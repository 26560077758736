import { FC } from 'react';
import { ISolutionsCardProps } from './solutionsCard.interface';

export const SolutionsCard: FC<ISolutionsCardProps> = (props) => {
  const {
    iconUrl,
    text,
    title,
    subtitle,
    list,
  } = props;

  return (
    <div className='py-6 px-4 lg:px-14 bg-[#0F3257] rounded-lg lg:w-2/4 '>
      <img
        src={iconUrl}
        alt="icon"
        className='w-[7.5rem] h-[7.5rem]'
      />

      <h3 className='text-2xl text-white mb-4'>
        {title}
      </h3>

      <p className='text-secondaryBlue leading-6 text-sm mb-8'>
        {text}
      </p>

      <h4 className='text-white mb-4'>
        {subtitle}
      </h4>

      <ul className=''>
        {list.map((item) => (
          <li
            key={item}
            className='flex items-center gap-4 text-secondaryBlue leading-6 text-sm'
          >
            <div className='bg-primaryBlue w-1 h-1 rounded-full'/>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};
