import KelvinH from '../assets/img/KelvinH.jpeg';
import AliciaD from '../assets/img/AliciaD.png';
import LisaK from '../assets/img/LisaK.png';
import JerryE from '../assets/img/JerryE.png';
import { ICustomerCardProps } from '../components/customerCard/customerCard.interface';

export const homeFeedbackData: ICustomerCardProps[] = [
  {
    id: 1,
    imgUrl: KelvinH,
    position: 'Notary',
    name: 'Kevin H',
    text: "This is probably the best kept secret in the industry! I was hesitant because I expected a bunch of hidden fees, but Notary Hub's bundled pricing is all-inclusive. This is the most accessible and affordable RON platform I've seen.",
  },
  {
    id: 2,
    imgUrl: AliciaD,
    position: 'Consumer',
    name: 'Alicia D',
    text: "Thank you Notary Hub for making all of this notarization stuff so much easier to understand. This is NOT my area of expertise and I’m thankful for a platform that’s user friendly for a novice like myself.",
  },
  {
    id: 3,
    imgUrl: LisaK,
    position: 'Notary',
    name: 'Lisa K',
    text: "Thanks to Notary Hub I’ve been able to reach clients beyond my immediate region that I would have never been able to reach before.",
  },
  {
    id: 4,
    imgUrl: JerryE,
    position: 'Consumer',
    name: 'Jerry E',
    text: "Closing on our house this year was much quicker and far more painless than it the first time around thanks to Notary Hub.",
  },
];
