import { FC } from "react";

import { States } from "./components/states/States";
import { SmallStates } from "./components/smallStates/SmallStates";
import { IUsaMapProps } from "./usaMap.interface";

export const UsaMap: FC<IUsaMapProps> = (props) => {
  const {
    className,
    isDarkTheme = true,
    smallStatesClasses,
    selectedState,
    isSuportingState,
    setSelectedState,
  } = props;
  return (
    <div className={`w-full h-full flex justify-center items-center relative ${className}`}>
      <States
        isDarkTheme={isDarkTheme}
        isSuportingState={isSuportingState}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
      />

      <SmallStates
        isDarkTheme={isDarkTheme}
        isSuportingState={isSuportingState}
        className={`hidden lg:flex absolute bottom-10 right-10 ${smallStatesClasses}`}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
      />
    </div>
  );
};
