import { FC } from 'react';

import { PricingCard } from '../../../../components/pricingCard/PricingCard';

export const MonthlyTab: FC = () => {
  return (
    <>
      <PricingCard
        price={15}
        subscription={'1m'}
        plan={'self'}
      />

      <PricingCard
        price={15}
        subscription={'1m'}
        plan={'corp'}
      />
    </>
  );
};
