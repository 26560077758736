import { useState } from "react";

export const useWaitingList = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>('');
  const [isSubmited, setIsSubmited] = useState(false);

  function isValidEmail(email: string) {
    return /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
  }

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };

  const onSubmitHandler = async () => {
    try {
      const response = await fetch('https://jsonplaceholder.typicode.com/posts', {
        method: 'post',
        body: email,
      });
      
      response.ok
        ? setIsSubmited(true)
        :setError('Something went wrong');
      
    } catch(e) {
      setError('Something went wrong');
    }
  };

  return {
    email,
    error,
    isSubmited,
    onChangeHandler,
    onSubmitHandler,
  }
};
