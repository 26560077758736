import {
  FC,
  ReactNode,
} from "react";

import { FormState } from "./FormState";
import { FormSubscription } from "./FormSubscription";
import { FormPlan } from "./FormPlan";
import { useRegistrationContext } from "../../../../hooks/useRegistarationContext";
import { IFormSteps } from "./form.interface";

export const Form: FC = () => {
  const { step } = useRegistrationContext();

  const formSteps: Record<IFormSteps, ReactNode> = {
    1: <FormState />,
    2: <FormPlan />,
    3: <FormSubscription />,
    4: null,
  };

  return (
    <section className="bg-primary w-screen pt-[5rem] pb-[10rem] xl:pt-[10rem]">
      <div className="container mx-auto">
        {formSteps[step]}
      </div>
    </section>
  );
};
