import { FC } from "react";
import { Button } from "@material-tailwind/react";

import subscriptionsMonth from '../../../../assets/icon/subscriptionsMonth.svg'
import subscriptionsAnnual from '../../../../assets/icon/subscriptionsAnnual.svg'
import subscriptions4Year from '../../../../assets/icon/subscriptions4Year.svg'
import { SubscriptionCard } from "../../../../components/subscriptionCard/SubscriptionCard";
import { ISubsription } from "./form.interface";
import { useRegistrationContext } from "../../../../hooks/useRegistarationContext";
import { FormTemplate } from "../../../../components/formTemplate/FormTemplate";
import { DescriptionSelf } from "./DescriptionSelf";

export interface ISubscriptionSelfProps {
  subsription: ISubsription;
  setSubscription: React.Dispatch<React.SetStateAction<ISubsription>>;
}

export const SubscriptionSelf: FC = () => {
  const {
    subsription,
    setSubscription,
    handleSubmite,
  } = useRegistrationContext();

  return (
    <div className="flex flex-col items-center gap-[3rem] lg:flex-row lg:items-start xl:gap-[6.25rem]">
      <FormTemplate
        subTitle="SELECT SUBSCRIPTION"
        title="Every plan has a base subscription"
      >
        <p className="text-secondaryBlue text-sm mb-8">
          Don’t worry if you change your mind, you can change this during the registration process.
        </p>

        <div className="flex flex-col gap-2 lg:gap-0 mb-8 lg:flex-row w-full">
          <SubscriptionCard
            isActive={subsription === '1m'}
            iconUrl={subscriptionsMonth}
            title="Month Subscription"
            price="15.00"
            onClick={() => setSubscription('1m')}
          />

          <SubscriptionCard
            isActive={subsription === '1y'}
            iconUrl={subscriptionsAnnual}
            title="Annual Subscription"
            text="Only $10/month"
            price="120.00"
            onClick={() => setSubscription('1y')}
          />

          <SubscriptionCard
            isActive={subsription === '4y'}
            iconUrl={subscriptions4Year}
            title="4 Year Subscription"
            price="240.00"
            text="Best value - $5/month"
            onClick={() => setSubscription('4y')}
          />  
        </div>

        <Button
          type="button"
          onClick={handleSubmite}
          className="mt-6 w-full normal-case rounded font-medium"
        >
          Register
        </Button> 
      </FormTemplate>
  
      <DescriptionSelf />
    </div>
  );
};
