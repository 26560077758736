import { FC } from 'react';

import { ISubscriptionCardProps } from './subscriptionCard.interface';
import { ReactComponent as CheckedIcon } from '../../assets/icon/checkedFull.svg';

export const SubscriptionCard: FC<ISubscriptionCardProps> = (props) => {
  const {
    isActive,
    iconUrl,
    title,
    price,
    text,
    onClick,
    className,
  } = props;

  return (
    <article
      className={`relative flex flex-row justify-between lg:flex-col lg:justify-center items-center gap-3 lg:gap-4 w-full p-2 rounded border
      border-lightGray border-solid ${isActive && 'bg-agry/30 border-agry'} ${className}`}
      onClick={onClick}
    >
      <img
        src={iconUrl}
        alt="icon"
        className='w-12 h-12'
      />

      <div className='flex flex-col items-start lg:items-center lg:gap-2'>
        <h2 className='font-semibold text-xs lg:text-sm lg:text-center'>
          {title}
        </h2>

        <p className='text-[0.5625rem] lg:text-xs text-agry h-3'>
          {text}
        </p>
      </div>


      <p className='text-xl lg:text-2xl font-semibold'> 
        <span className='text-secondaryBlue text-base'>
          $
        </span>
        {price}
      </p>

      {isActive && (
        <CheckedIcon className='absolute top-[50%] left-[-8px] translate-y-[-50%]' />
      )}
    </article>
  );
};
