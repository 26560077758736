import { FC } from 'react';

import phone from '../../../assets/img/phone.png';

export const NotaryTour: FC = () => {
  return (
    <section className='bg-primary'>
      <div className='container mx-auto lg:flex lg:justify-center lg:h-[30rem]'>
        <div className='max-w-[500px]'>
          <img
            src={phone}
            alt="Devices With Mrk"
            className='hidden lg:block lg:mt-[-6rem]'
          />
        </div>

        <div className='flex flex-col items-center justify-center py-20 lg:w-[30rem] lg:items-start'>
          <h3 className="font-semibold text-[2.5rem] text-center text-white leading-[3rem] mb-10 lg:text-6xl lg:w-[29rem] lg:text-left">
            See Notary Hub in Action
          </h3>

          <img
            src={phone}
            alt="Devices With Mrk"
            className='lg:hidden'
          />

          <div className="flex flex-col items-center min-w-[18.75rem] gap-4">
            <a
              href="https://lp.notaryhub.com/request_demo"
              className="py-3 px-7 w-full block bg-agry rounded text-white text-center hover:opacity-90 duration-300"
            >
              Request a Tour
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
