import { FC } from "react";

import { ReactComponent as HomeChainIcon } from '../../../assets/icon/homeChain.svg'
import { LogoTitle } from "../../../components/logoTitle/LogoTitle";
import { ChaineCard } from "../../../components/chainCard/ChainCard";

export const HomeChain: FC = () => {
  return (
    <section className="max-w-full mx-auto pt-[2rem] pb-[0] lg:pb-[6rem] overflow-hidden">
      <div className="container mx-auto mb-[2rem]">
        <LogoTitle title="Four simple steps" />
      </div>

      <div className='max-w-[1440px] overflow-x-auto ml-[-6.25rem] md:mx-auto'>
        <HomeChainIcon className="w-[1440px]"/>

        <div className="flex px-[120px] min-w-[1440px] pb-10">
          <ChaineCard
            className="w-1/4 mt-[-100px]"
            title="Register"
            text="Register your Notary Hub account."
          />

          <ChaineCard
            className="w-1/4"
            title="Upload"
            text="Easily upload and schedule your notarization."
          />

          <ChaineCard
            className="w-1/4 mt-[40px]"
            title="Connect"
            text="Review your document together via real-time document sharing and live video conference."
          />

          <ChaineCard
            className="w-1/4 mt-[-30px]"
            title="Sign & Download"
            text="Notarize the document, download the final copy and you're ready to go."
          />
        </div>
      </div>
    </section>
  );
};
