import { FC } from 'react';

import { VideoBlock } from '../../../components/videoBlock/VideoBlock';


export const NotaryYouTube: FC = () => {
  return (
    <section className="relative container mx-auto flex justify-center pt-[3rem] lg:pt-[8rem]">
      <div className='flex flex-col items-start w-full'>
        <VideoBlock
          title='What is RON?'
          text={<>
            Remote Online Notarization (RON) allows documents to be notarized in electronic form with the signers signing using electronic stamp, and an 
            {' '}
            <a
              href="/articles/x509-document-signing-certificates-why-do-i-need"
              className='underline'
            >
              electronic signature.
            </a>
            {' '}
            The notary and constituent interact real-time via online audio-video technology.
          </>}
          videoUrl='https://www.youtube.com/watch?v=huJydqbEcRs'
          className='mb-24 lg:mb-40'
        />

        <VideoBlock
          reverse={true}
          title='Why do I need RON?'
          text={`The State laws to allow for RON have been accelerating during the coronavirus pandemic, and expectations for remote services have never been higher. 

          Adding RON to your tool belt is a vital and modern method to assist constituents, grow your business, save time, and save money. By meeting virtually, rather than the traditional in-person requirements, both you and your constituents will experience an array of benefits that elevate the signing experience.`}
          videoUrl='https://www.youtube.com/watch?v=CEQZivJOtM0'
          className='mb-[3.125rem] lg:mb-[10rem]'
        />
      </div>
    </section>
  );
};
