import { FC } from 'react';

import { ReactComponent as CheckedIcon } from "../../../../assets/icon/checked.svg";
import { LogoTitle } from "../../../../components/logoTitle/LogoTitle";

export const TrustedContent: FC = () => {
  return (
    <>
      <LogoTitle
        title="Connecting with a Notary"
        theme="dark"
        classNames="mb-10"
      />

      <p className="text-secondaryBlue text-sm mb-8 whitespace-pre-line">
        {`
          Find the right online notary for you — whether you know exactly who you’re looking for within your organization, or this is your first time engaging a notary public — Notary Hub makes it easy.
          
          All Notary Hub notaries are licensed and official notaries public and have been authorized to perform as a remote online notary (RON).

          Within the Notary Hub platform, consumers have the ability to submit a request for notarization & be connected to a readily available RON or schedule an appointment with their preferred online notary. Once an appointment is secured consumers & notaries will have audio-visual connectivity to ensure the digital signing experience is completed.

          No matter your need, we’re here to help find the right solution for you.
        `}
      </p>

      <ul className="flex flex-col items-start justify-center gap-2">
        <li className="flex justify-center items-center gap-4 text-sm text-white" >
          <CheckedIcon />

          Licensed public notaries.
        </li>

        <li className="flex justify-center items-center gap-4 text-sm text-white" >
          <CheckedIcon />

          Endorsed for RON in your state.
        </li>

        <li className="flex justify-center items-center gap-4 text-sm text-white" >
          <CheckedIcon />
          
          Vetted & approved by Notary Hub.
        </li>

        <li className="flex justify-center items-center gap-4 text-sm text-white" >
          <CheckedIcon />

          Online & available 24/7.
        </li>
      </ul>
    </>
  );
};
