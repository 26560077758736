import { FC } from 'react';

import { VideoBlock } from '../../../components/videoBlock/VideoBlock';


export const ConsumerYouTube: FC = () => {
  return (
    <VideoBlock
      reverse
      title='Why do I need RON?'
      text={`A remote online notary is a legally commissioned notary public who is authorized to conduct notarizations over the internet via digital tools and a live audio-video call.

      Remote notarization allows for a more streamlined, cost effective, and secure way to get your documents notarized. `}
      videoUrl='https://www.youtube.com/watch?v=QeMTy4E8yYg'
      className='mt-2 mb-9 lg:my-40'
    />
  );
};
