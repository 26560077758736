import { FC } from 'react';
import { IBenefitsCardProps } from './benefitsCard.interface';

export const BenefitsCard: FC<IBenefitsCardProps> = (props) => {
  const {
    icon,
    title,
    text,
  } = props;

  return (
    <div className='flex flex-col gap-4 p-4 min-w-[21.5635rem] min-h-[13.5rem] rounded border border-solid border-lightGray overflow-hidden
    hover:shadow-around hover:border-white duration-300 md:w-[23.125rem] md:h-[20rem] md:p-8'>
      <div className='flex justify-center items-center w-[4rem] h-[4rem] bg-bgBlue md:mb-4'>
        <img
          src={icon}
          alt="icon"
        />
      </div>

      <h4 className='font-semibold'>
        {title}
      </h4>

      <p className='text-sm text-gray'>
        {text}
      </p>
    </div>
  );
};
