import { FC } from 'react';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

import { MonthlyTab } from './MonthlyTab';
import { AnnualTab } from './AnnualTab';
import { FourYeatTab } from './FourYeatTab';

export const PricesTabs: FC = () => {
  const data = [
    {
      label: "Monthly",
      value: "Monthly",
      desc: <MonthlyTab />,  
    },
    {
      label: "Annual",
      value: "Annual",
      desc: <AnnualTab />,
    },
    {
      label: "4-Year",
      value: "4-Year",
      desc: <FourYeatTab />,
    },
  ];

  return (
    <section className='container mx-auto mb-10'>
      <Tabs value="Annual">
        <TabsHeader
          className='w-max mx-auto bg-primary bg-opacity-1 mt-[4.325rem] mb-[3.325rem] p-[3px]'
          indicatorProps={{
            className: "bg-primaryBlue shadow-none rounded text-white ",
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              key={value}
              value={value}
              className='text-white lg:text-2xl px-5'
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>

        <TabsBody>
          {data.map(({ value, desc }) => (
            <TabPanel
              key={value}
              value={value}
            >
              <div className='flex flex-col justify-center items-center gap-5 lg:flex-row lg:justify-around lg:items-start'>
                {desc}
              </div>
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>  
    </section>
  );
};
