export const subscriptionsIncludesData = [
  {
    id: 2,
    text: '1x Notary user (each additional user: $10/mo)',
  },
  {
    id: 3,
    text: 'Secure Digital Signature',
  },
  {
    id: 4,
    text: 'Secure Digital Stamp',
  },
  {
    id: 5,
    text: 'Your own distinct storefront URL you can share with constituents',
  },
  {
    id: 6,
    text: '24x7 access to the remote notary platform',
  },
  {
    id: 7,
    text: 'Live video and audio during signing sessions',
  },
  {
    id: 8,
    text: 'Live document editing during signing sessions',
  },
  {
    id: 9,
    text: 'Optional document sync between all parties',
  },
  {
    id: 10,
    text: 'Digital notary Journal',
  },
  {
    id: 11,
    text: 'Scheduling features',
  },
  {
    id: 12,
    text: 'Multi-party signings',
  },
  {
    id: 13,
    text: 'Customizable notifications of new signing requests',
  },
  {
    id: 14,
    text: 'Customizable Notary Blocks that you can re-use across signings',
  },
  {
    id: 15,
    text:
      <span>
        Cloud-based document signing
        {' '}
        <span className='text-gray'>
          (NOTE: requires digital signature from SSL.com)
        </span>
      </span>,
  },
];
