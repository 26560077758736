import { FC } from "react";

import { NotaryStart } from "./components/notaryStart/NotaryStart";
import { NotaryYouTube } from "./components/NotaryYouTube";
import { NotaryDuties } from "./components/NotaryDuties";
import { NotaryChain } from "./components/NotaryChain";
import { NotaryPlans } from "./components/notaryPlans/NotaryPlans";
import { NotaryBecome } from "./components/NotaryBecom";
import { NotaryTour } from "./components/NotaryTour";
import useDocumentTitle from "../../hooks/useDocumentTitle";

interface NotaryPageProps {
  title?: string;
}
export const NotaryPage: FC<NotaryPageProps> = ({ title = "Notary" }) => {
  useDocumentTitle(title);

  return (
    <main>
      <NotaryStart />

      <NotaryYouTube />

      <NotaryDuties />

      <NotaryChain />

      <NotaryPlans />

      <NotaryBecome />

      <NotaryTour />
    </main>
  );
};
