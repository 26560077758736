import KelvinH from '../assets/img/KelvinH.jpeg';
import AliciaD from '../assets/img/AliciaD.png';
import LisaK from '../assets/img/LisaK.png';
import JerryE from '../assets/img/JerryE.png';
import { ICustomerCardProps } from '../components/customerCard/customerCard.interface';

export const consumerFeedbackData: ICustomerCardProps[] = [
  {
    id: 1,
    imgUrl: LisaK,
    position: 'Notary',
    name: 'Lisa K',
    text: "Thanks to Notary Hub I’ve been able to reach clients beyond my immediate region that I would have never been able to reach before.",
  },
  {
    id: 2,
    imgUrl: KelvinH,
    position: 'Notary',
    name: 'Kevin H',
    text: "My wife is immune compromised, which means COVID took a huge toll on my ability to offer notary services to clients. Now that I am set up on Notary Hub I’ve been able to re-instate & grow a whole sector of my business I never thought I’d be able to again.",
  },
  {
    id: 3,
    imgUrl: AliciaD,
    position: 'Consumer',
    name: 'Alicia D',
    text: "Thank you Notary Hub for making all of this notarization stuff so much easier to understand. This is NOT my area of expertise and I’m thankful for a platform that’s user friendly for a novice like myself.",
  },
  {
    id: 4,
    imgUrl: JerryE,
    position: 'Consumer',
    name: 'Jerry E',
    text: "Closing on our house this year was much quicker and far more painless than it the first time around thanks to Notary Hub.",
  },
];
