import { FC } from 'react';

import { SplitBlock } from '../../../../components/splitBlock/SplitBlock';
import { UsaMap } from '../../../../components/usaMap/UsaMap';
import { StateSelection } from './components/StateSelection';
import { useRegistrationContext } from '../../../../hooks/useRegistarationContext';

export const NotaryPlans: FC = () => {
  const {
    selectedState,
    setSelectedState,
    isSuportingState,
  } = useRegistrationContext();

  return (
    <SplitBlock
      leftSide={
        <StateSelection />
      }
      rightSide={
        <div className='w-full max-w-[53.125rem] h-full flex items-center justify-center'>
          <UsaMap
            isDarkTheme={false}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            smallStatesClasses='bottom-0 right-[10px]'
            isSuportingState={isSuportingState}
          />
        </div>
      }
    />
  );
};
