import { FC } from 'react';

import PensillIcon from '../../../assets/icon/pensill.svg';
import LeverageIcon from '../../../assets/icon/leverage.svg';
import AmplifylIcon from '../../../assets/icon/amplify.svg';
import MoneyIcon from '../../../assets/icon/money.svg';
import ScrollIcon from '../../../assets/icon/scroll.svg';
import CalendarIcon from '../../../assets/img/calendarIcon.png'
import { NotaryDutiesCard } from '../../../components/notaryDutiesCard/NotaryDutiesCard';
import { LogoTitle } from '../../../components/logoTitle/LogoTitle';

export const NotaryDuties: FC = () => {
  return (
    <section className='bg-primary'>
      <div className='container mx-auto py-[4.375rem]'>
        <LogoTitle
          title='You as a Remote Online Notary'
          theme='dark'
          classNames='mb-[4.375rem]'
        />

        <div className='flex flex-col items-center justify-center gap-2 lg:gap-10 lg:flex-row lg:h-[29rem]'>
          <div className='flex flex-col items-center justify-center gap-2 lg:gap-10 h-full w-full'>
            <NotaryDutiesCard
              iconUrl={PensillIcon}
              text={
                <>
                  <span className='font-bold'>Simplify</span> the signing process for constituents & reduce your paperwork with our fully digital signing sessions.
                </>
              }
            />

            <NotaryDutiesCard
              iconUrl={AmplifylIcon}
              text={
                <>
                  <span className='font-bold'>Amplify</span> your reach beyond your immediate geography, even out of state.
                </>
              }
            />

            <NotaryDutiesCard
              iconUrl={ScrollIcon}
              text={
                <>
                  <span className='font-bold'>Modernize</span> your notary practice and elevate the signing experience. 
                </>
              }
            />
          </div>
  
          <div className='flex flex-col items-center justify-center gap-2 lg:gap-10 h-full w-full'>
            <NotaryDutiesCard
              iconUrl={LeverageIcon}
              text={
                <>
                  <span className='font-bold'>Leverage</span> automated and secure online tools that reduce risk of identity fraud.
                </>
              }
            />

            <NotaryDutiesCard
              iconUrl={MoneyIcon}
              text={
                <>
                  <span className='font-bold'>Save</span> time and money traveling, parking, and waiting for appointments to start. 
                </>
              }
            />

            <NotaryDutiesCard
              iconUrl={CalendarIcon}
              text={
                <>
                  <span className='font-bold'>Make your own schedule</span> — log in any time from anywhere to review and accept new business. 
                </>
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};
