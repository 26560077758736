import { FC, useState } from "react";
import ReactPlayer, { Config } from "react-player";

import { IYouTubeProps } from "./youTube.interface";
import { ReactComponent as ReloadIcon } from "../../assets/icon/reload.svg";

export const YouTube: FC<IYouTubeProps> = (props) => {
  const { videoUrl } = props;
  const [isPlay, setIsPlay] = useState(false);
  const [isBlock, setIsBlock] = useState(false);

  const onStartHandler = () => {
    setIsBlock(false);
    setIsPlay(true);
  };

  const onEndedHandler = () => {
    setIsBlock(true);
    setIsPlay(false);
  };

  const config: Config = {
    youtube: {
      playerVars: {
        rel: 0,
      },
    },
  };

  return (
    <div className="relative pt-[56.25%]">
      <ReactPlayer
        className="absolute top-0 left-0 w-full h-full"
        style={{
          borderRadius: '1rem',
          overflow: 'hidden',
        }}
        width="100%"
        height="100%"
        config={config}
        playsinline={false}
        controls
        loop={false}
        url={videoUrl}
        playing={isPlay}
        onStart={onStartHandler}
        onEnded={onEndedHandler}
      />

      {isBlock && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center z-1 bg-lightGray ">
          <button
            type="button"
            className="flex items-center gap-2 bg-primaryBlue px-5 py-3 rounded text-white"
            onClick={onStartHandler}
          >
            <ReloadIcon className="w-[1.5rem] h-[1.5rem]" />
            Play Vidoe
          </button>
        </div>
      )}
    </div>
  );
};
