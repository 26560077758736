import { FC } from 'react';
import { INotaryDutiesCardProps } from './notaryDutiesCard.interface';

export const NotaryDutiesCard: FC<INotaryDutiesCardProps> = (props) => {
  const {
    iconUrl,
    text
  } = props;

  return (
    <article className='flex items-center gap-4 py-6 px-4 bg-[#0F3257] rounded-lg w-full h-full overflow-hidden'>
      <div className='flex justify-center items-center min-w-[4rem] min-h-[4rem] max-w-[4rem] max-h-[4rem] rounded-lg bg-[#123962]'>
        <img
          src={iconUrl}
          alt="icon"
        />
      </div>

      <p className='text-white text-xs lg:text-sm'>
        {text}
      </p>
    </article>
  );
};
