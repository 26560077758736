import { FC } from 'react';

import devicesWithMark from '../../../assets/img/devicesWithMark.png';

export const ConsumerBecome: FC = () => {
  return (
    <section className='bg-primary lg:mt-[7rem]'>
      <div className='container mx-auto lg:flex lg:justify-center lg:h-[30rem]'>
        <div className='flex flex-col items-center justify-center py-20 lg:w-[30rem] lg:items-start'>
          <h3 className="font-semibold text-[2.5rem] text-center text-white leading-[3rem] mb-10 lg:text-6xl lg:w-[29rem] lg:text-left">
            Connect With A Notary
          </h3>

          <img
            src={devicesWithMark}
            alt="Devices With Mrk"
            className='lg:hidden'
          />

          <div className="flex flex-col items-center min-w-[18.75rem] gap-4">
            <a
              href="https://app.notaryhub.com/notarize-a-document-online-find-a-notary?register-from=consumers"
              className="py-3 px-7 w-full block bg-secondary rounded text-white text-center hover:opacity-90 duration-300"
            >
              Schedule A Notary
            </a>
          </div>
        </div>

        <div className='max-w-[600px]'>
          <img
            src={devicesWithMark}
            alt="Devices With Mrk"
            className='hidden lg:block lg:mt-[-7rem]'
          />
        </div>
      </div>
    </section>
  );
};
