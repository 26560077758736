import bagIcon from "../../assets/icon/bag.svg";
import columnsIcon from "../../assets/icon/columns.svg";

export const solutionsCardMock = [
  {
    id: 1,
    iconUrl: bagIcon,
    title: "Real Estate",
    text: "Notary Hub by EverythingLegal remote online notary system is a professional real estate closing service and software. Simply request a signing, and one of our notaries will take care of the rest. Real estate agents and lenders are all always welcome to attend the virtual closing with the signers.",
    subtitle: "Using our service makes real estateclosings easy!",
    list: [
      "No technology training required",
      "No need to drive to an office",
      "Easy scheduling or day of service",
    ],
  },
  {
    id: 2,
    iconUrl: columnsIcon,
    title: "Business",
    text: "Many industries rely on notarized documents. You no longer need to schedule an onsite notary. With Notary Hub's digital notary platform you can easily schedule or get same-day service on the documents you need notarized.",
    subtitle: "Industries that rely on our technology:",
    list: [
      "Law firms",
      "Private wealth management",
      "Financial services",
      "Auto insurance",
      "And more...",
    ],
  },
];
