import {
  FC,
  useState,
} from 'react';
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import { NavLink } from 'react-router-dom';

import { ReactComponent as ArrowDownIcon } from '../../assets/icon/arrowDown.svg';

export const RegistrationButton: FC = () => {
  const [openPopover, setOpenPopover] = useState(false);
  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };

  const linkClasses = 'text-white hover:text-primaryBlue';
  const activeLinkClasses = 'text-primaryBlue';

  return (
    <Popover
      open={openPopover}
      handler={setOpenPopover}
      placement='bottom-end'
    >
      <PopoverHandler {...triggers}>
        <button
          type="button"
          className={`flex items-center justify-center gap-2 duration-300 ${openPopover ? activeLinkClasses : linkClasses}`}
        >
          Registration
          <ArrowDownIcon className={`${openPopover && 'rotate-180'}`} />
        </button>
      </PopoverHandler>

      <PopoverContent
        {...triggers}
        className="flex flex-col bg-agry p-1 border-agry rounded-none z-20"
      > 
        <a
          href='https://app.notaryhub.com/notarize-a-document-online-find-a-notary'
          target='blank'
          className="px-4 py-1 rounded text-white hover:text-primaryBlue duration-300"
        >
          Consumer - Schedule a Notary
        </a>

        <NavLink
          to={"/registration"}
          className="px-4 py-1 rounded text-white hover:text-primaryBlue duration-300"
        >
          Notary - Register as a RON
        </NavLink>
      </PopoverContent>
    </Popover>
  );
};
