import { useContext } from 'react';

import { RegistrationContext } from '../contexts/RegistrationContext';

export const useRegistrationContext = () => {
  const context = useContext(RegistrationContext);

  if (context === null) {
    throw new Error('useRegistrationContext must be used within a RegistrationProvider');
  }

  return context;
};