import { FC } from "react";
import { IBurgerButtonProps } from "./burgerButton.interface";

export const BurgerButton: FC<IBurgerButtonProps> = (props) => {
  const {
    isWhiteTheme,
    isOpen,
    onClick,
  } = props;
  const genericHamburgerLine = `h-0.5 w-4 rounded-full transition ease transform duration-300 ld:hidden ${isWhiteTheme ? 'bg-white' : 'bg-black'}`;

  return (
    <button
      className="flex gap-0.5 flex-col justify-center items-center group"
      onClick={onClick}
    >
      <div
        className={`${genericHamburgerLine} ${
          isOpen
            ? "rotate-45 translate-y-1 opacity-50 group-hover:opacity-100"
            : "opacity-50 group-hover:opacity-100"
        }`}
      />
      <div
        className={`${genericHamburgerLine} ${
          isOpen ? "opacity-0" : "opacity-50 group-hover:opacity-100"
        }`}
      />
      <div
        className={`${genericHamburgerLine} ${
          isOpen
            ? "-rotate-45 -translate-y-1 opacity-50 group-hover:opacity-100"
            : "opacity-50 group-hover:opacity-100"
        }`}
      />
      <div
        className={`${genericHamburgerLine} ${
          isOpen ? "opacity-0" : "opacity-50 group-hover:opacity-100"
        }`}
      />
    </button>
  );
};
