import { FC, useState } from "react";

import { IAccordionProps } from "./accordion.interface";
import { AccordionComponent } from "../accordionComponent/AccordionComponent";

export const Accordion: FC<IAccordionProps> = (props) => {
  const { mock } = props;
  const [openId, setOpenId] = useState<number[]>([]);

  const handleOpen = (id: number) => {
    openId.includes(id)
      ? setOpenId([...openId].filter((itemId) => itemId !== id))
      : setOpenId([...openId, id]);
  };

  const mockLength = mock.length / 2;
  const firstHalf = mock.slice(0, mockLength);
  const secondHalf = mock.slice(mockLength);

  return (
    <div className="md:flex gap-10">
      <div className="md:w-1/2">
        {firstHalf.map((accordion) => (
          <AccordionComponent
            key={accordion.id}
            id={accordion.id}
            header={accordion.header}
            text={accordion.text}
            openId={openId}
            handleOpen={handleOpen}
          />
        ))}
      </div>

      <div className="md:w-1/2">
        {secondHalf.map((accordion) => (
          <AccordionComponent
            key={accordion.id}
            id={accordion.id}
            header={accordion.header}
            text={accordion.text}
            openId={openId}
            handleOpen={handleOpen}
          />
        ))}
      </div>
    </div>
  );
};
