import { FC } from 'react';

import { LogoTitle } from '../../../components/logoTitle/LogoTitle';
import { Accordion } from '../../../components/accordion/Accordion';
import { faqDataConsumer } from '../../../data/faqDataConsumer';

export const ConsumerFAQ: FC = () => {
  return (
    <section className='container mx-auto py-14 lg:pb-[7.5rem]'>
      <LogoTitle
        title='Frequently Asked Question'
        classNames='mb-14'
      />
      
      <Accordion mock={faqDataConsumer} />
    </section>
  );
};
