import { FC } from "react";

import { PricingCard } from "../../../../components/pricingCard/PricingCard";

export const AnnualTab: FC = () => {
  return (
    <>
      <PricingCard
        price={120}
        average={10}
        subscription={"1y"}
        plan={"self"}
      />

      <PricingCard
        price={120}
        average={10}
        subscription={"1y"}
        plan={"corp"}
      />
    </>
  );
};
