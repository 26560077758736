import { FC } from 'react';

import { IPlanCardProps } from './planCard.interface';
import { ReactComponent as CheckedIcon } from '../../assets/icon/checkedFull.svg';

export const PlanCard: FC<IPlanCardProps> = (props) => {
  const {
    isActive,
    iconUrl,
    title,
    text,
    onClick,
    className,
  } = props;

  return (
    <article
      className={`relative flex items-center gap-4 w-full p-4 rounded border
      border-solid ${isActive ? 'bg-[rgba(71,190,125,.1)] border-agry' : 'border-lightGray'} ${className}`}
      onClick={onClick}
    >
      <img
        src={iconUrl}
        alt="icon"
        className='w-12 h-12'
      />

      <div>
        <h2 className='text-sm lg:text-sm font-semibold mb-2'>
          {title}
        </h2>

        <p className='text-agry text-xs'> 
          {text}
        </p>
      </div>

      {isActive && (
        <CheckedIcon className='absolute top-[50%] left-[-8px] translate-y-[-50%]' />
      )}
    </article>
  );
};
