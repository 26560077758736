export const selfRonPlan = [
  {
    id: 1,
    text: 'Get paid for signings',
  },
  {
    id: 2,
    text: 
      <span>
        Inexpensive start-up costs
        {' '}
        <span className='text-gray'>
          (as low as $5/month with our 4-year plan)
        </span>
      </span>,
  },
  {
    id: 3,
    text: 'Pay for your entire subscription with just a couple of notarizations',
  },
];
