import { FC } from 'react';
import { Button } from '@material-tailwind/react';

import { IStateButtonProps } from './stateButton.interface';
import { getStateHighlight } from '../../usaMap.const';

export const StateButton: FC<IStateButtonProps> = (props) => {
  const {
    state,
    selectedState,
    isSuportingState,
    isDarkTheme,
    setSelectedState,
  } = props;

  const bg = getStateHighlight(state, selectedState, isSuportingState, isDarkTheme);

  const clases = `flex items-center justify-center w-[30px] bg-[#3c424a] rounded
  hover:bg-secondaryBlue normal-case rounded font-medium `

  return (
    <Button
      size='sm'
      type='button'
      onClick={() => setSelectedState(state)}
      className={clases}
      style={{
        backgroundColor: `${bg}`
      }}
    >
      {state}
    </Button>
  );
};
