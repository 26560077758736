import { FC } from 'react';
import { LogoTitle } from '../../../components/logoTitle/LogoTitle';
import { solutionsCardMock } from '../../../components/solutionsCard/solutionsCard.const';
import { SolutionsCard } from '../../../components/solutionsCard/SolutionsCard';

export const ConsumerSolutions: FC = () => {
  return (
    <section className='bg-primary py-[3rem] lg:py-[7.5rem mb-[3rem]'>
      <div className='container mx-auto'>
        <LogoTitle
          title='Solutions'
          theme='dark'
          classNames='mb-[3rem]'
        />

        <div className='flex flex-col gap-6 lg:flex-row justify-center'>
          {solutionsCardMock.map((card) => (
            <SolutionsCard
              key={card.id}
              iconUrl={card.iconUrl}
              title={card.title}
              text={card.text}
              subtitle={card.subtitle}
              list={card.list}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
