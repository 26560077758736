import { FC } from "react";

import homeStart from "../../../../assets/img/homeStart.png";
import homeStartMobile from "../../../../assets/img/homeStartMobile.png";
import { StartScreen } from "../../../../components/startScreen/StartScreen";
import { StartContent } from "./StartContent";

export const HomeStart: FC = () => {
  return (
    <StartScreen
      content={<StartContent />}
      image={
        <>
          <div className='flex justify-center items-center max-w-[45rem]'>
            <img
              src={homeStart}
              alt="Devices"
              className='hidden lg:block'
            />
          </div>

          <img
            src={homeStartMobile}
            alt="Devices"
            className='lg:hidden mt-[-2.5rem]'
          />
        </>
      }
    />
  );
};
