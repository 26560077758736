import { FC } from 'react';

import { ICustomerCardProps } from './customerCard.interface';
import { ReactComponent as ApostropheIcon } from '../../assets/icon/apostrophe.svg'

export const CustomerCard: FC<ICustomerCardProps> = (props) => {
  const {
    imgUrl,
    name,
    position,
    text,
  } = props;

  return (
    <article className='flex flex-col min-w-[20rem] max-w-[29.375rem] h-[25rem] px-4 py-6 rounded border border-solid border-lightGray'>
      <div className='flex items-center gap-4 mb-6'>
        <img
          src={imgUrl}
          alt={`Customer ${name}`}
          className='rounded-full w-24 h-24'
        />

        <div className='flex flex-col justify-center items-start gap-2'>
          <h4 className='font-semibold'>
            {name}
          </h4>

          <h5 className='text-sm text-secondary'>
            {position}
          </h5>
        </div>
      </div>

      <ApostropheIcon className='mb-4'/>

      <p className='text-left text-gray text-sm overflow-hidden mb-6'>
        {text}
      </p>
    </article>
  );
};
