import { FC } from "react";

import { ConsumerStart } from "./components/ConsumerStart/ConsumerStart";
import { ConsumerYouTube } from "./components/ConsumerYouTube";
import { ConsumerBecome } from "./components/ConsumerBecom";
import { ConsumerSolutions } from "./components/ConsumersSolutions";
import { ConsumerFeedback } from "./components/ConsumerFeedback";
import { ConsumerPricing } from "./components/ConsumerPricing";
import { ConsumerFAQ } from "./components/ConsumerFAQ";
import useDocumentTitle from "../../hooks/useDocumentTitle";

interface ConsumerPageProps {
  title?: string;
}

export const ConsumerPage: FC<ConsumerPageProps> = ({ title = "Consumer" }) => {
  useDocumentTitle(title);

  return (
    <main>
      <ConsumerStart />

      <ConsumerYouTube />

      <ConsumerSolutions />

      <ConsumerFeedback />

      <ConsumerPricing />

      <ConsumerFAQ />

      <ConsumerBecome />
    </main>
  );
};
