import { FC } from 'react';

import { ISessionCardProps } from './sessionCard.interface';
import { ReactComponent as CheckedIcon } from '../../assets/icon/checkedFull.svg';

export const SessionCard: FC<ISessionCardProps> = (props) => {
  const {
    isActive,
    sessionsCount,
    price,
    onClick,
    className,
  } = props;

  const spanClasses = 'text-secondaryBlue font-normal text-xs md:text-base'

  return (
    <article
      className={`relative flex items-center justify-center gap-4 w-full p-4 rounded border
      border-lightGray border-solid ${isActive && 'bg-agry/30 border-agry'} ${className}`}
      onClick={onClick}
    >
      <h2 className='lg:text-2xl text-left font-semibold lg:w-[120px]'>
        {`${sessionsCount} `}

        <span className={spanClasses}>
          Sessions
        </span>
      </h2>

      <div className='text-secondaryBlue text-base w-[1px] h-6 bg-[lightGray]' />

      <p className='lg:text-2xl font-semibold'> 
        <span className={spanClasses}>
          $
        </span>

        {`${price} `}

        <span className={spanClasses}>
          / Per Month
        </span>
      </p>

      {isActive && (
        <CheckedIcon className='absolute top-[50%] left-[-8px] translate-y-[-50%]' />
      )}
    </article>
  );
};
