import { FC } from 'react';

import './startScreen.css';
import { IStartScreenProps } from './startScreen.interface';

export const StartScreen: FC<IStartScreenProps> = (props) => {
  const {
    content,
    image
  } = props;

  return (
    <section className="section">
      <div className="section__content">
        <div className="container mx-auto">
          <div className="section__body">
            {content}
          </div>
        </div>
      </div>

      <div className="section__image">
        <div className='section__image-box'>
          {image}
        </div>
      </div>
    </section>
  );
};
