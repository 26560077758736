import { FC } from "react";

import { SplitBlock } from '../../../../components/splitBlock/SplitBlock'
import { NotarySlider } from "../../../../components/notarySlider/NotarySlider";
import { TrustedContent } from "./TructedContent";
import { notaryCardData } from "../../../../data/notaryCardData";

export const Trusted: FC = () => {
  return (
    <SplitBlock
      leftSide={<TrustedContent />}
      rightSide={<NotarySlider slides={notaryCardData} />}
    />
  );
};
