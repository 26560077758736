import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { IBurgerNavProps } from './burgerNav.interface';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icon/arrowRight.svg';

export const BurgerNav: FC<IBurgerNavProps> = (props) => {
  const {
    openBurgerHandler,
    setNavPageHandler,
  } = props;

  const linkClasses = 'flex justify-between items-center w-full text-white';

  return (
    <nav className="container mx-auto flex flex-col items-start gap-10">
      <NavLink
        to={"consumer"}
        onClick={openBurgerHandler}
        className={linkClasses}
      >
        Consumers
        <ArrowRightIcon />
      </NavLink>

      <NavLink
        to={"/notary"}
        onClick={openBurgerHandler}
        className={linkClasses}
      >
        Notaries
        <ArrowRightIcon />
      </NavLink>

      <a
        href="/#"
        className={linkClasses}
      >
        News
        <ArrowRightIcon />
      </a>

      <div className='flex items-center gap-5 flex-col w-full mt-[3rem]'>
        <button
          type='button'
          onClick={() => setNavPageHandler('registration')}
          className="w-[18.75rem] h-[2.5rem] text-center px-4 py-1 bg-white rounded text-primaryBlue"
        >
          Register
        </button>

        <a
          href={"/#"}
          className="text-white"
        >
          Log In
        </a>
      </div>
    </nav>
  );
};
