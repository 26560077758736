import { FC } from 'react';

import { ILogoTitleProps } from './logoTitle.interface';
import logoTitle from '../../assets/img/logoTitle.png';
import logoTitleWhite from '../../assets/img/logoTitleWhite.png';

export const LogoTitle: FC<ILogoTitleProps> = (props) => {
  const {
    title,
    theme = 'light',
    classNames,
  } = props;

  const iconClasses = "h-[7rem] md:h-max object-contain inline-block -ml-[1rem] md:-ml-[2rem] ";

  return (
    <div className={`relative inline-block w-max h-max lg:ml-[-50px] ${classNames}`}>
      <h2 className='w-max font-bold text-[1.25rem] text-primaryRed inline-block absolute top-5 left-[1.5rem] md:text-[2rem] md:top-[3.4375rem] md:left-11 whitespace-pre-line'>
        {title}
      </h2>

      <img
        src={theme === 'light' ? logoTitle : logoTitleWhite}
        alt={title}
        className={iconClasses}
      />
    </div>
  );
};