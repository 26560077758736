import { FC } from "react";

import { SubscriptionCorp } from './SubscriptionCorp';
import { SubscriptionSelf } from "./SubscriptionSelf";
import { useRegistrationContext } from "../../../../hooks/useRegistarationContext";

export const FormSubscription: FC = () => {
  const { plan } = useRegistrationContext();

  return (
    <>
      {plan === 'corp' && (
        <SubscriptionCorp />
      )}

      {plan === 'self' && (
        <SubscriptionSelf />
      )}
    </>
  );
};
