import { FC } from 'react';

export const Footer: FC = () => {
  return (
    <footer className='shadow-up'>
      <div className='flex flex-col justify-center items-center container mx-auto py-6 text-xs lg:flex-row lg:justify-between'>
        <ul className='flex gap-4 mb-4 lg:mb-0'>
          <li>
            <a href="https://app.notaryhub.com/what-is-RON-remote-online-notary">
              FAQ
            </a>
          </li>

          <li>•</li>

          <li>
            <a href="https://app.notaryhub.com/contact">
              Contact
            </a>
          </li>

          <li>•</li>

          <li>
            <a href="https://app.notaryhub.com/customer-support">
            Customer Support
            </a>
          </li>
        </ul>

        <div className='flex flex-col items-center lg:gap-3 lg:flex-row lg:justify-center'>
          <div className='flex items-center mb-2 lg:mb-0'>
            <a href="https://app.notaryhub.com/terms-and-conditions">
              Terms & Conditions
            </a>
            
            <span className='mx-4'>
              | 
            </span>

            <a href="https://app.notaryhub.com/privacy-policy">
              Privacy Policy
            </a>
          </div>

          <p className='text-gray'>
            © EverythingLegal 2022. All Right Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
