import {
  FC,
  Fragment,
} from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { IAccordionComponentProps } from "./accordionComponent.interface";
import { AccordionIcon } from "./AccordionIcon/AccordionIcon";
 
export const AccordionComponent: FC<IAccordionComponentProps> = (props) => {
  const {
    id,
    openId,
    header,
    text,
    handleOpen,
  } = props;

  const isOpen = openId.includes(id);
 
  return (
    <Fragment>
      <Accordion
        open={isOpen}
        icon={
            <AccordionIcon
              isOpen={isOpen}
            />
          }
        className="font-['Poppins'] whitespace-pre-line text-gray"
        >

        <AccordionHeader
          className="text-xs text-left lg:text-base py-4 border-b border-lightGray border-solid"
          onClick={() => handleOpen(id)}
        >
          <h2>
            {header}
          </h2>
        </AccordionHeader>

        <AccordionBody>
          <p className="whitespace-pre-line">
            {text}
          </p>
        </AccordionBody>
      </Accordion>
    </Fragment>
  );
};
