import { FC } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";

import usaMapData from "../../../../data/usaMapData.json";
import { IUsaMapProps } from "../../usaMap.interface";
import { getStateHighlight } from "../../usaMap.const";

export const States: FC<IUsaMapProps> = (props) => {
  const {
    isDarkTheme = true,
    isSuportingState,
    selectedState,
    setSelectedState,
  } = props;

  return (
    <ComposableMap projection="geoAlbersUsa" className="max-w-[1100px]"  style={{width: "100%"}}>
      <Geographies geography={usaMapData}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              className="cursor-pointer"
              onClick={() => setSelectedState(geo.properties.postal)}
              fill={getStateHighlight(geo.properties.postal, selectedState, isSuportingState, isDarkTheme)}
              stroke={isDarkTheme ? '#082544' : 'white'}
              strokeWidth={1}
            />
          ))
        }
      </Geographies>
    </ComposableMap>
  );
};
