import { FC } from "react";

import { Form } from "./component/form/Form";
import { PlanPrices } from "./component/planPrices/PlanPrices";
import useDocumentTitle from "../../hooks/useDocumentTitle";

interface RegistrationPageProps {
  title?: string;
}

export const RegistrationPage: FC<RegistrationPageProps> = ({ title = "Register" }) => {
  useDocumentTitle(title);

  return (
    <main>
      <Form />

      <PlanPrices />
    </main>
  );
};
