import { FC } from "react";
import { Button } from "@material-tailwind/react";

import subscriptionsMonth from '../../../../assets/icon/subscriptionsMonth.svg'
import subscriptionsAnnual from '../../../../assets/icon/subscriptionsAnnual.svg'
import subscriptions4Year from '../../../../assets/icon/subscriptions4Year.svg'
import { SessionCard } from "../../../../components/sessionCard/SessionCard";
import { SubscriptionCard } from "../../../../components/subscriptionCard/SubscriptionCard";
import { ISessions, ISubsription } from "./form.interface";
import { useRegistrationContext } from "../../../../hooks/useRegistarationContext";
import { FormTemplate } from "../../../../components/formTemplate/FormTemplate";
import { DescriptionCorp } from "./DescriptionCorp";

export interface ISubscriptionsCorpProps {
  subsription: ISubsription;
  setSubscription: React.Dispatch<React.SetStateAction<ISubsription>>;
  sessions: ISessions;
  setSessions: React.Dispatch<React.SetStateAction<ISessions>>;
}

export const SubscriptionCorp: FC = () => {
  const {
    sessions,
    subsription,
    setSessions,
    setSubscription,
    handleSubmite,
  } = useRegistrationContext();

  return (
    <div className="flex flex-col items-center gap-[3rem] lg:flex-row lg:items-start xl:gap-[6.25rem]">
      <FormTemplate
        subTitle="SELECT CORP RON SUBSCRIPTION"
        title="Every plan has a base subscription"
      >
        <p className="text-secondaryBlue text-sm mb-8">
          Don’t worry if you change your mind, you can change this during the registration process.
        </p>

        <div className="flex flex-col gap-2 lg:gap-0 mb-8 lg:flex-row w-full">
          <SubscriptionCard
            isActive={subsription === '1m'}
            iconUrl={subscriptionsMonth}
            title="Monthly Subscription"
            price="15.00"
            onClick={() => setSubscription('1m')}
          />

          <SubscriptionCard
            isActive={subsription === '1y'}
            iconUrl={subscriptionsAnnual}
            title="Annual Subscription"
            price="120.00"
            text="Only $10/month"
            onClick={() => setSubscription('1y')}
          />

          <SubscriptionCard
            isActive={subsription === '4y'}
            iconUrl={subscriptions4Year}
            title="4 Year Subscription"
            price="240.00"
            text="Best value - $5/month"
            onClick={() => setSubscription('4y')}
          />  
        </div>

        <h2 className="font-semibold text-[1.625rem] mb-4 lg:text-xl text-center lg:text-left">
          Corp RON signing sessions are pre-paid 
        </h2>

        <SessionCard
          isActive={sessions === '20'}
          sessionsCount='20'
          price='50.00'
          onClick={() => setSessions('20')}
          className="mb-2"
        />

        <SessionCard
          isActive={sessions === '50'}
          sessionsCount='50'
          price='60.00'
          onClick={() => setSessions('50')}
          className="mb-2"
        />

        <SessionCard
          isActive={sessions === '100'}
          sessionsCount='100'
          price='100.00'
          onClick={() => setSessions('100')}
          className="mb-8"
        />    

        <Button
          type="button"
          onClick={handleSubmite}
          className="w-full normal-case rounded font-medium"
        >
          Register your Notary Hub account
        </Button>
      </FormTemplate>

      <DescriptionCorp />
    </div>
  );
};
