import { FC } from "react";

import { NavLink } from "react-router-dom";
import { StartInfo } from "../../../../components/startInfo/StartInfo";

export const StartContent: FC = () => {
  return (
    <>
      <StartInfo
        subtitle="FAST & SECURE"
        title={`Remote Notary \n Services`}
        text="Easy to use, convenient, secure, legal, trusted.  Connect with a notary anywhere, anytime, without the need for a physical meeting."
      />

      <div className="flex flex-col items-center min-w-[18.75rem] text-sm gap-4 lg:flex-row lg:min-w-[22.5rem] lg:max-w-[25rem]">
        <NavLink
          to="/consumer"
          className="py-3 px-7 w-full bg-secondary rounded text-white text-center hover:opacity-90 duration-300"
        >
          I
            <span className="font-bold">
              {' need '}
            </span>
          a notary
        </NavLink>

        <NavLink
          to="/notary"
          className="py-3 px-7 w-full bg-agry rounded text-white text-center hover:opacity-90 duration-300"
        >
          I 
          <span className="font-bold">
            {' am '}
          </span>
          a notary
        </NavLink>
      </div>
    </>
  );
};
