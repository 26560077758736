import { FC, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { PricesTabs } from './PricesTabs';

export const PlanPrices: FC = () => {
  const location = useLocation();
  const selRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selRef.current && location.hash === '#pricing-RON') {
      selRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section
      ref={selRef}
      className='bg-lightGray'
    >
      <PricesTabs />
      
      <div className='container mx-auto pb-10'>
        <h2 className='text-xl font-bold mb-3'>
          Need a Document Signing Certificate? We’ve got you covered.
        </h2>

        <p className='text-gray'>
          *Notary Hub by EverythingLegal has partnered with 
          {' '}
          <a
            href="https://www.ssl.com/"
            target='blank'
            className='underline'
          >
            ssl.com
          </a>
          {' '}
          where you will purchase your
          {' '}
          <a
            href="/articles/x509-document-signing-certificates-why-do-i-need"
            target='blank'
            className='underline'
          >
            notary compliant X.509 document signing certificate
          </a>
          {' '}
          (prices vary from $60-$195 depending on whether you select 1-year or 4-year subscription term for the certificate). This is a requirement in addition to the Notary Hub subscription costs to securely digitally sign documents after they've been completed.
        </p>
      </div>
    </section>
  );
};
