import { FC, ReactElement, useState } from 'react';
import { createPortal } from 'react-dom';

import { IBurgerMenuProps, INavPage } from './burgerMenu.interface';
import { BurgerNav } from '../burgerNav/burgerNav';
import { BurgerButton } from '../burgerButton/BurgerButton';
import { RegisterNav } from '../registerNav/RegisterNav';
import { Footer } from '../../../footer/Footer';

export const BurgerMenu: FC<IBurgerMenuProps> = (props) => {
  const {
    isOpen,
    isWhiteTheme,
    openBurgerHandler,
  } = props;
  const [navPage, setNavPage] = useState<INavPage>('menu');
  const portal = document.getElementById("portal-root") as HTMLDivElement;

  const setNavPageHandler = (page: INavPage) => {
    setNavPage(page);
  };

  const navigation: Record<INavPage, ReactElement> = {
    'menu': <BurgerNav openBurgerHandler={openBurgerHandler} setNavPageHandler={setNavPageHandler} />,
    'registration': <RegisterNav setNavPageHandler={setNavPageHandler} />,
  }

  return (
    <>
      <BurgerButton
        isOpen={isOpen}
        isWhiteTheme={isWhiteTheme}
        onClick={openBurgerHandler}
      />

      {isOpen &&
        createPortal(
          <div
            className='fixed top-0 left-0 right-0 bottom-0 backdrop-blur-sm'
            onClick={openBurgerHandler}
          >
            <div
              className="sidebar fixed top-0 left-0 right-0 pt-[8rem] w-screen h-screen bg-primary cursor-pointer z-[1] lg:hidden flex flex-col place-content-between"
              onClick={(e) => e.stopPropagation()}
            >
              {navigation[navPage]}

              <div className='bg-white '>
                <Footer />
              </div>
            </div>
          </div>,
          portal
        )}
    </>
  );
};
