import { FC } from "react";

import { StateButton } from "../stateButton/StateButton";
import { ISmallStatesProps } from "./smallStates.interface";

const smallStatesData = ["NH", "RI", "NJ", "MD", "VT", "MA", "CT", "DE", "DC"];

export const SmallStates: FC<ISmallStatesProps> = (props) => {
  const {
    className,
    selectedState,
    isSuportingState,
    isDarkTheme,
    setSelectedState,
  } = props;

  return (
    <div
      className={`flex flex-wrap justify-center items-center gap-1 w-[70px] ${
        className && className
      }`}
    >
      {smallStatesData.map((state) => (
        <StateButton
          key={state}
          isDarkTheme={isDarkTheme}
          isSuportingState={isSuportingState}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          state={state}
        />
      ))}
    </div>
  );
};
