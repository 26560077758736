import { FC } from "react";

import { NavLink } from "react-router-dom";
import { RegistrationButton } from "./RegistrationButton";

export const Navigation: FC = () => {
  const linkClasses = 'text-white hover:text-primaryBlue duration-300';
  const activeLinkClasses = 'duration-300 text-primaryBlue';

  return (
    <nav className={`justify-center items-center gap-6 hidden md:flex md:text-xs lg:text-base`}>
      <NavLink
        to={"/notary"}
        className={({ isActive }) => isActive ? activeLinkClasses : linkClasses }
      >
        For Notary
      </NavLink>

      <NavLink
        to={"/consumer"}
        className={({ isActive }) => isActive ? activeLinkClasses : linkClasses }
      >
        For Consumer
      </NavLink>

      <a
        href={"/articles"}
        className="text-white hover:text-primaryBlue duration-300"
      >
        Articles
      </a>

      <RegistrationButton />

      <a
        href={"https://app.notaryhub.com/login"}
        className="px-4 py-1 bg-white rounded text-primaryBlue hover:bg-lightGray duration-300"
      >
        Log In
      </a>
    </nav>
  );
};
