import { FC } from 'react';

import stampWithMrk from '../../../assets/img/stampWithMark.png';
import { NavLink } from 'react-router-dom';

export const NotaryBecome: FC = () => {
  return (
    <section className='container mx-auto pt-[2.5rem] pb-[7.5rem] lg:flex lg:justify-center'>
      <div className='flex flex-col items-center justify-center py-20 lg:w-[30rem] lg:items-start'>
        <h2 className="font-semibold text-secondary tracking-[0.6875rem] mb-5 lg:text-2xl">
          BECOME A RON
        </h2>

        <h3 className="font-semibold text-[2.5rem] text-center leading-[3rem] mb-10 lg:text-6xl lg:w-[29rem] lg:text-left">
          on Notary Hub
        </h3>

        <img
            src={stampWithMrk}
            alt="Stamp With Mark"
            className='lg:hidden'
          />

        <div className="flex flex-col items-center min-w-[18.75rem] gap-4">
          <NavLink
            to="/registration#pricing-RON"
            className="py-3 px-7 w-full block bg-secondary rounded text-white text-center hover:opacity-90 duration-300"
          >
            See All Plans
          </NavLink>
        </div>
      </div>

      <img
        src={stampWithMrk}
        alt="Stamp With Mark"
        className='hidden lg:block'
      />
    </section>
  );
};
