import { FC } from 'react';

import hand from '../../../assets/img/hand.jpg';
import { LogoTitle } from '../../../components/logoTitle/LogoTitle';

export const ConsumerPricing: FC = () => {
  return (
    <section className='container mx-auto flex justify-center items-center lg:items-end gap-[6.25rem] flex-col lg:flex-row lg:py-40'>
      <div className='w-full'>
        <LogoTitle
          title='Pricing'
          classNames='mb-[3.125rem]'
        />

        <img
          src={hand}
          alt="pricing"
          className='max-w-[45rem] max-h-[15rem] w-full rounded-[3rem] object-cover'
        />
      </div>

      <div className='max-w-[31.25rem] text-gray text-sm leading-6 whitespace-pre-line'>
        <p className='mb-12'>
          The cost of a remote online notarization is dependent on the number of documents signed and notarized during each signing session. 
          {'\n'}
          {'\n'}
          A single notarization ("seal") is $<b>$21</b>; additional seals are <b>$15</b> each.
        </p>

        <h3 className='mb-4'>
          What’s included:
        </h3>

        <ul className='list-disc'>
          <li className='py-1 ml-8'>
            Live meeting with a licensed remote online notary via audio-visual call
          </li>

          <li className='py-1 ml-8'>
            ID verification & KBA (Knowledge Based Assessment)
          </li>

          <li className='py-1 ml-8'>
            Document upload & signing
          </li>

          <li className='py-1 ml-8'>
            Downloadable document files
          </li>

          <li className='py-1 ml-8'>
            Streamlined payment process
          </li>
        </ul>
      </div>
    </section>
  );
};
