import { FC } from 'react';

import { LogoTitle } from '../../../components/logoTitle/LogoTitle';
import { ComingOutCard } from '../../../components/comingOutCard/ComingOutCard';
import {
  iAmConsumerMock,
  iAmNotaryMock,
} from '../../../data/comingOutCardData';

export const HowItWork: FC = () => {
  return (
    <section className={`mb-[48rem] lg:mb-0 bg-primary bg-[url('./assets/img/howItWork.png')] bg-cover bg-no-repeat bg-right bg-blend-multiply lg:bg-contain lg:bg-left`}>
      <div className={`container mx-auto h-[45rem] py-16 lg:flex justify-between lg:h-[40.625rem]`}>
        <div className='max-w-[26.25rem]'>
          <LogoTitle 
            title='How it Work?'
            theme='dark'
            classNames='mb-10'
          />

          <p className='text-secondaryBlue leading-6 text-sm mb-8 whitespace-pre-line'>
          {`Notary Hub is your one stop shop to safely, securely, and legally execute remote online notarization. Getting started is simple — create an account and complete identity verification. When you’re ready to engage in the notarization process just log into your Notary Hub account, schedule a signing appointment, and be online at the designated time with your audio and video enabled.

            Your notary will walk you through the entire signing process. Once complete, your documents will be available for immediate download.`}
          </p>
        </div>

        <div className='flex flex-col justify-center items-center gap-4 mb-[8.125rem]'>
          <ComingOutCard
            buttonColor='agry'
            iconUrl={iAmConsumerMock.icon}
            title={iAmConsumerMock.title}
            text={iAmConsumerMock.text}
            redirectTo={iAmConsumerMock.redirectTo}
          />

          <ComingOutCard
            buttonColor='secondary'
            iconUrl={iAmNotaryMock.icon}
            title={iAmNotaryMock.title}
            text={
              <>
                Learn 
                {' '}
                <a
                  href="/articles/how-to-become-a-remote-online-notary"
                  className='underline'
                >
                  how to become an online notary
                </a>
                {' '}
                , and find a subscription that fits your notary needs, whether you’re an independent notary or you’re a notary who works on a company’s behalf. 
              </>
            }
            redirectTo={iAmNotaryMock.redirectTo}
          />
        </div>
      </div>
    </section>
  );
};
