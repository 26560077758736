import { useState } from "react";
import {
  IFormSteps,
  IPlan,
  ISessions,
  ISubsription,
} from "../pages/registration/component/form/form.interface";
import allStates from '../data/allStates.json';
import suportingStates from '../data/suportingStates.json';
import { submiteRegistrationData } from "../utils/submiteRegistrationData";

export const useRegistration = () => {
  const [step, setStep] = useState<IFormSteps>(1);
  const [selectedState, setSelectedState] = useState("WA");
  const [plan, setPlan] = useState<IPlan>('self');
  const [subsription, setSubscription] = useState<ISubsription>('1y');
  const [sessions, setSessions] = useState<ISessions>('50');
  
  const isSuportingState = suportingStates.includes(selectedState);
  const selectedStateName = allStates.find((state) => state.abbreviation === selectedState)?.name || "Selected State";

  const handleSubmite = () => {
    submiteRegistrationData(plan, subsription, sessions, selectedState)
  };

  const stepBackHandler = (step: IFormSteps) => {
    setSessions(null);
    setStep(step);
  }

  return {
    step,
    selectedState,
    plan,
    sessions,
    subsription,
    selectedStateName,
    isSuportingState,
    setPlan,
    setStep,
    setSessions,
    setSelectedState,
    setSubscription,
    handleSubmite,
    stepBackHandler,
  };
};
