import { FC } from "react";
import {
  Select,
  Option,
  Button,
} from "@material-tailwind/react";

import allStates from "../../../../data/allStates.json";
import { UsaMap } from "../../../../components/usaMap/UsaMap";
import { useRegistrationContext } from "../../../../hooks/useRegistarationContext";
import { FormTemplate } from "../../../../components/formTemplate/FormTemplate";
import { WaitingList } from "../../../../components/waitingList/WaitingList";

export const FormState: FC = () => {
  const {
    selectedStateName,
    selectedState,
    isSuportingState,
    setSelectedState,
    setStep,
  } = useRegistrationContext();

  const onSelectStateHandler = (state: string | undefined) => {
    if (!state) return;

    setSelectedState(state);
  };

  const onClickHandler = () => {
    setStep(2);
  };

  return (
    <section className="flex justify-center items-center lg:justify-between lg:gap-[1rem] xl:gap-[6.25rem]">
      <FormTemplate
        subTitle="SELECT STATE"
        title="In which State will you deliver RON services?"
      >
        <Select
          label="Select State"
          onChange={onSelectStateHandler}
          value={selectedState}
          className="mb-8"
        >
          {allStates.map((state) => (
            <Option key={state.id} value={state.abbreviation}>
              {`${state.abbreviation}, ${state.name}`}
            </Option>
          ))}
        </Select>

        {!isSuportingState && <WaitingList />}

        {isSuportingState && (
          <Button
            type="button"
            onClick={onClickHandler}
            className="mt-6 mb-16 w-full normal-case rounded font-medium"
          >
            {`See All Plans For ${selectedStateName}`}
          </Button>
        )}

        <p className="text-secondaryBlue text-sm">
          Not a registered Remote Online Notary (RON)?
        </p>

        <a
          href={`https://everythinglegal-notary.tawk.help/article/ron-endorsement-${selectedState.toLocaleLowerCase()}`}
          target="blank"
          className="text-primaryBlue text-sm underline decoration-inherit block h-[40px]"
        >
          {`Find out more about the process to become one in ${selectedStateName}`}
        </a>
      </FormTemplate>

      <UsaMap
        className="hidden lg:flex"
        isSuportingState={isSuportingState}
        smallStatesClasses="bottom-[-30px] right-[-10px]"
        selectedState={selectedState}
        setSelectedState={setSelectedState}
      />
    </section>
  );
};
