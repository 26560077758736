import OnlineIcon from '../assets/icon/online.svg';
import AdaptabilityIcon from '../assets/icon/adaptability.svg';
import SequrityIcon from '../assets/icon/sequrity.svg';
import DocumentIcon from '../assets/icon/document.svg';
import ServiceIcon from '../assets/icon/service.svg';
import TrustedIcon from '../assets/icon/trusted.svg';

export const benefitsCardMock = [
  {
    id: 1,
    icon: OnlineIcon,
    title: "100% Cloud Based",
    text: "Notary Hub is a 100% cloud based platform — saving notaries public and consumers time and paper. ",
  },
  {
    id: 2,
    icon: AdaptabilityIcon,
    title: "Adaptability",
    text: "Accessible from your laptop or desktop, take Notary Hub with you wherever duty calls.",
  },
  {
    id: 3,
    icon: SequrityIcon,
    title: "Security",
    text: "Notary Hub uses identity verification technology, digitally authenticated signatures, and digitally embedded fingerprints to allow users to securely sign and store their notarized documents.",
  },
  {
    id: 4,
    icon: DocumentIcon,
    title: "Document Preparation Tools",
    text: "Streamline the signing process with our built in document preparation tools. ",
  },
  {
    id: 5,
    icon: ServiceIcon,
    title: "Full Service",
    text: "Experience red carpet service from onboarding to completing your first digital signing appointment.",
  },
  {
    id: 6,
    icon: TrustedIcon,
    title: "Trusted",
    text: "A trusted entity for notaries and consumers since 1953. Notary Hub connects notaries and consumers through a secure state approved platform.",
  },
];
