import { FC } from "react";

import { NavLink } from "react-router-dom";
import { StartInfo } from "../../../../components/startInfo/StartInfo";

export const StartContent: FC = () => {
  return (
    <>
      <StartInfo
        subtitle="ARE YOU A NOTARY?"
        title={`Find the RON plan \n that works for you`}
        text=""
      />

      <div className="flex flex-col items-center lg:items-start justify-start min-w-[18.75rem] gap-8 lg:max-w-[25rem]">
        <div className="flex flex-col w-full gap-2 lg:flex-row">
          <NavLink
            to="/registration#pricing-RON"
            className="py-3 px-7 block bg-secondary rounded text-white text-center hover:opacity-90"
          >
            See All Plans
          </NavLink>

          <a
            href="https://lp.notaryhub.com/request_demo"
            className="py-3 px-7 block bg-agry rounded text-white text-center hover:opacity-90 "
          >
            Request a Tour
          </a>
        </div>

        <div className="flex flex-col">
          <p className="font-medium text-gray text-sm mb-2">
            <span className="font-bold">Looking for a Notary</span> to assist with a signing? 
          </p>

          <NavLink
            to="/consumer"
            className="font-medium text-sm underline"
          >
            See pricing and book a notary in minutes.
          </NavLink>
        </div>
      </div>
    </>
  );
};
