import { FC } from 'react';

import { PricingCard } from '../../../../components/pricingCard/PricingCard';

export const FourYeatTab: FC = () => {
  return (
    <>
      <PricingCard
        price={240}
        average={5}
        subscription={'4y'}
        plan={'self'}
      />

      <PricingCard
        price={240}
        average={5}
        subscription={'4y'}
        plan={'corp'}
      />
    </>
  );
};
