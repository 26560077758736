import { FC } from 'react';

import { ReactComponent as PointBlueIcon } from "../../assets/icon/pointBlue.svg";

const corpIncludes = [
  {
    id: 1,
    text: "1 Notary user",
  },
  {
    id: 2,
    text: "Pre-paid signing sessions",
  },
  {
    id: 3,
    text: "Securely stored Digital Signature",
  },
  {
    id: 4,
    text: "Securely stored Digital Notary Stamp",
  },
  {
    id: 5,
    text: "24x7 access to the notarization platform",
  },
  {
    id: 6,
    text: "Marketing to promote yourself for mobile or RON Notarial opportunities",
  },
  {
    id: 7,
    text: "Audio/visual live sessions",
  },
  {
    id: 8,
    text: "ID Verification / KBA for your constituents",
  },
  {
    id: 9,
    text: "Scheduling",
  },
];

export const CorpPlanDescription: FC = () => {
  return (
    <>
      <h2>INCLUDED</h2>

      <ul className="mb-5">
        {corpIncludes.map((item) => (
          <li
            key={item.id}
            className="flex items-center gap-4 py-1 text-white text-xs lg:text-base"
          >
            <span>
              <PointBlueIcon className="w-[12px] h-[12px]" />
            </span>

            {item.text}
          </li>
        ))}
      </ul>

      <div className='w-full flex justify-center mb-5'>
          <span className='text-[56px] leading-none'>
            +
          </span>
      </div>

      <p className="mb-5 pl-[1rem]">
        Select a package of pre-paid signing sessions to be billed monthly to your account:
      </p>

      <ul className="mb-5 pl-[2rem]">
        <li className="flex items-center gap-4 py-1 text-white text-xs lg:text-base">
          <span>
            <PointBlueIcon className="w-[12px] h-[12px]" />
          </span>

          20 RON Sessions - 50$
        </li>

        <li className="flex items-center gap-4 py-1 text-white text-xs lg:text-base">
          <span>
            <PointBlueIcon className="w-[12px] h-[12px]" />
          </span>

          50 RON Sessions - 60$
        </li>

        <li className="flex items-center gap-4 py-1 text-white text-xs lg:text-base">
          <span>
            <PointBlueIcon className="w-[12px] h-[12px]" />
          </span>

          100 RON Sessions - 100%
        </li>
      </ul>

      <p className="text-[10px] text-primaryRed mb-5 pl-[1rem]">
        Pre-paid session counts are aggregated totals for all Notary users within the account. RON sessions exceeding pre-paid count within subscription month: $4.99/each.
      </p>

      <p className="mb-5 pl-[1rem]">
        All accounts include 1 Notary user. Each additional user: $10/mo
      </p>

      <h2 className="pl-[1rem]">
        NOT INCLUDED
      </h2>

      <ul>
        <li className="flex items-center gap-4 py-1 text-white text-xs lg:text-base pl-[1rem]">
          <span>
            <PointBlueIcon className="w-[12px] h-[12px]" />
          </span>

          Required Digital Sertificate
        </li>
      </ul>

      <p className="text-xs text-primaryRed pl-[1rem]">
        Notary Hub will facilitate your discounted purchase of our cloud-based SSL digital certificate after your subscription is chosen.
      </p>
    </>
  );
};
