import { FC } from 'react';

import { IFormTemplateProps } from './formTemplate.interface';
import { FormStepper } from '../formStepper/FormStepper';

export const FormTemplate: FC<IFormTemplateProps> = (props) => {
  const {
    children,
    title,
    subTitle,
  } = props;

  return (
    <div className='lg:max-w-[32rem] w-full'>
      <FormStepper />

      <article className="flex flex-col justify-center px-6 py-10 rounded bg-white lg:min-h-[38.75rem] w-full">
        <h2 className="font-semibold text-secondary text-center tracking-[0.6875rem] mb-5 lg:text-left lg:w-full">
          {subTitle}
        </h2>

        <h1 className="font-semibold text-[1.625rem] lg:text-[2rem] text-center mb-6 lg:text-left">
          {title}
        </h1>
        
        {children}
      </article>
    </div>
  );
};
