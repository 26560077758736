import { IPlan, ISessions, ISubsription } from "../pages/registration/component/form/form.interface";

export const submiteRegistrationData = (plan: IPlan, subscription: ISubsription, sessions: ISessions, selectedState?: string) => {
  const endPoint = plan === 'self'
  ? 'https://app.notaryhub.com/remote-online-notary-become-ron'
  : 'https://app.notaryhub.com/remote-online-notary-for-enterprise';

  let sub = `${plan}-${subscription}`;

  if (plan === 'corp') {
    sub = `${sub}-${sessions}`
  };

  const url = new URL(endPoint);
  if (selectedState) {
    url.searchParams.append('state', selectedState);
  }
  url.searchParams.append('sub', sub);

  window.location.replace(url);
};
