import { INotaryCardProps } from '../components/notaryCard/notaryCard.interface';
import notaryImg from '../assets/img/notary.png';

export const notaryCardData: INotaryCardProps[] = [
  {
    id: 1,
    imgUrl: notaryImg,
    name: 'Charlie Calzoni',
    sessions: 3000,
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
    city: "Beaumont",
    state: "TX",
  },
  {
    id: 2,
    imgUrl: notaryImg,
    name: 'Charlie Calzoni',
    sessions: 3000,
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
    city: "Beaumont",
    state: "TX",
  },
  {
    id: 3,
    imgUrl: notaryImg,
    name: 'Charlie Calzoni',
    sessions: 3000,
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
    city: "Beaumont",
    state: "TX",
  },
  {
    id: 4,
    imgUrl: notaryImg,
    name: 'Charlie Calzoni',
    sessions: 3000,
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
    city: "Beaumont",
    state: "TX",
  },
  {
    id: 5,
    imgUrl: notaryImg,
    name: 'Charlie Calzoni',
    sessions: 3000,
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
    city: "Beaumont",
    state: "TX",
  },
  {
    id: 6,
    imgUrl: notaryImg,
    name: 'Charlie Calzoni',
    sessions: 3000,
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
    city: "Beaumont",
    state: "TX",
  },
  {
    id: 7,
    imgUrl: notaryImg,
    name: 'Charlie Calzoni',
    sessions: 3000,
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
    city: "Beaumont",
    state: "TX",
  },
  {
    id: 8,
    imgUrl: notaryImg,
    name: 'Charlie Calzoni',
    sessions: 3000,
    description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio.',
    city: "Beaumont",
    state: "TX",
  },
];
