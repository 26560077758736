import { FC } from "react";

import './splitBlock.css';
import { ISplitBlockProps } from "./splitBlock.interface";

export const SplitBlock: FC<ISplitBlockProps> = (props) => {
  const {
    leftSide,
    rightSide,
  } = props;
  return (
    <section className="split">
      <div className="split__left">
        <div className="split__left-container">
          {leftSide}
        </div>
      </div>

      <div className="split__right">
        {rightSide}
      </div>
    </section>
  );
};
