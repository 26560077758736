import { FC } from 'react';
import Button from '@material-tailwind/react/components/Button';

import { IPricingCardProps } from './pricingCard.interface';
import { CorpPlanDescription } from './CorpPlanDescription';
import { SelfPlanDescription } from './SelfPlanDescription';
import { submiteRegistrationData } from '../../utils/submiteRegistrationData';

export const PricingCard: FC<IPricingCardProps> = (props) => {
  const {
    subscription,
    plan,
    price,
    average,
  } = props;

  const planString = `${plan.charAt(0).toLocaleUpperCase()}${plan.slice(1)}`;
  const subscriptionString = {
    '1m': 'Monthly',
    '1y': 'Annual',
    '4y': '4-Year',
  }[subscription];

  const handleSubmite = () => {
    submiteRegistrationData(plan, subscription, '50')
  };

  return (
    <article className='bg-primary rounded-[2rem] p-[1rem] lg:p-[3rem] text-white h-max max-w-[31.25rem]'>
      <h2 className='font-semibold text-[24px]'>
        {`${planString} RON - ${subscriptionString} Plan`}
      </h2>

      <div className='flex items-start gap-2 leading-none mb-6'>
        <span className='text-[56px] font-semibold'>
        {`$${price}`}
        </span>

        <div className='flex flex-col text-[24px] whitespace-pre-line'>
          .00

          {'\n'}

          {subscriptionString}

          <span className='text-agry text-xs h-4'>
            {average && (
              <>
                {`Average $${average}/mo`}
              </>
            )}
          </span>
        </div>
      </div>

      <div className='flex w-full justify-center'>
        <Button
          type="button"
          onClick={handleSubmite}
          className="mb-6 w-max normal-case rounded font-medium"
        >
          {`Register ${planString} RON ${subscriptionString}`}
        </Button>
      </div>

      {plan === 'corp' 
        ? <CorpPlanDescription />
        : <SelfPlanDescription />
      }
    </article>
  );
};
