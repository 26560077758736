import {
  FC,
  useMemo,
} from 'react';

import { IRegistrationProviderProps } from './registrationProvider.interface';
import { useRegistration } from '../hooks/useRegistration';
import { RegistrationContext } from '../contexts/RegistrationContext';

export const RegistrationProvider: FC<IRegistrationProviderProps> = (props) => {
  const { children } = props;

  const {
    step,
    selectedState,
    plan,
    sessions,
    subsription,
    selectedStateName,
    isSuportingState,
    setPlan,
    setStep,
    setSessions,
    setSelectedState,
    setSubscription,
    handleSubmite,
    stepBackHandler,
  } = useRegistration();

  const values = useMemo(() => ({
    step,
    selectedState,
    plan,
    sessions,
    subsription,
    selectedStateName,
    isSuportingState,
    handleSubmite,
    stepBackHandler,
    setPlan,
    setStep,
    setSessions,
    setSelectedState,
    setSubscription,
  }), [
    step,
    selectedState,
    plan,
    sessions,
    subsription,
    selectedStateName,
    isSuportingState,
    handleSubmite,
    stepBackHandler,
    setPlan,
    setStep,
    setSessions,
    setSelectedState,
    setSubscription,
  ]);

  return (
    <RegistrationContext.Provider value={values}>
      {children}
    </RegistrationContext.Provider>
  );
};
