import { FC } from 'react';

import { IAcoordionIconProps } from './accordionIcon.interface';
import { ReactComponent as PlusIcon } from '../../../assets/icon/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icon/minus.svg';

export const AccordionIcon: FC<IAcoordionIconProps> = (props) => {
  const {
    isOpen,
  } = props;

  return (
    <span className="flex items-center justify-center w-[1.125rem] h-[1.125rem] ">
      {isOpen
        ? <MinusIcon />
        : <PlusIcon />
      }
    </span>
  );
};
