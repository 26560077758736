import {
  FC,
  useState,
  useEffect,
} from "react";
import {
  NavLink,
  useLocation,
} from "react-router-dom";

import logo from "../../assets/img/logo.png";
import logoWhite from "../../assets/img/logoWhite.png";
import { Navigation } from './Navigation';
import { BurgerMenu } from "./components/burgerMenu/BurgerMenu";

export const Header: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const isWhiteTheme = isOpen || location.pathname === '/registration';

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }

    if (!isOpen) {
      document.body.removeAttribute("style");
    }
  }, [isOpen]);

  const openBurgerHandler = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className={`absolute top-0 left-0 right-0 z-[2] ${isOpen && 'border-b border-secondaryBlue border-solid'}`}>
      <div className='container mx-auto flex justify-between py-5 md:py-9'>
        <NavLink
          to={"/"}
          onClick={() => setIsOpen(false)}
        >
          <img
            src={isWhiteTheme ? logoWhite : logo}
            alt="notary-hub"
            className={`w-[174px] h-[32px]`}
          />
        </NavLink>

        <div className="hidden lg:block">
          <Navigation />
        </div>

        <div className="lg:hidden">
          <BurgerMenu
            isOpen={isOpen}
            isWhiteTheme={isWhiteTheme}
            openBurgerHandler={openBurgerHandler}
          />
        </div>
      </div>
    </header>
  );
};
