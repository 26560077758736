import { FC } from 'react';

import { CustomerSlider } from '../../../components/customerSlider/CustomerSlider';
import { homeFeedbackData } from '../../../data/homeFeedbackData';

export const HomeFeedback: FC = () => {
  return (
    <CustomerSlider slides={homeFeedbackData} />
  );
};
