import {
  FC,
  useRef,
} from "react";
import {
  Swiper,
  SwiperSlide,
} from "swiper/react";
import { Navigation } from "swiper";
import { IconButton } from "@material-tailwind/react";
import "swiper/css/navigation";
import "swiper/css";

import { ReactComponent as ChevronRightIcon } from "../../assets/icon/chevronRight.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/icon/chevronLeft.svg";
import { NotaryCard } from "../notaryCard/NotaryCard";
import { breakpoints } from "../customerSlider/customerSlider.const";
import { INotarySliderProps } from "./notarySlider.interface";

export const NotarySlider: FC<INotarySliderProps> = ({slides}) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const controlButoonClasses = "w-[3rem] h-[3rem] flex justify-center items-center bg-lightBlue";

  return (
    <div className="flex flex-col-reverse lg:flex-col">
      <div className="flex justify-center gap-4 mt-[2.25rem] lg:ml-[300px]">
        <IconButton
          ref={navigationPrevRef}
          type="button"
          className={controlButoonClasses}
        >
          <ChevronLeftIcon className="text-gray" />
        </IconButton>

        <IconButton
          ref={navigationNextRef}
          type="button"
          className={controlButoonClasses}
        >
          <ChevronRightIcon className="text-gray" />
        </IconButton>
      </div>

      <div className="w-[600px] md:w-[1000px] 2xl:w-[1300px] overflow-visible">
        <Swiper
          modules={[Navigation]}
          spaceBetween={0}
          breakpoints={breakpoints}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
            disabledClass: "scale-90 bg-lightGray",
          }}
          onBeforeInit={(swiper) => {
            if (typeof swiper.params.navigation !== "boolean") {
              swiper.params.navigation!.nextEl = navigationNextRef.current;
              swiper.params.navigation!.prevEl = navigationPrevRef.current;
            }
          }}
        >
          {slides.map((notary) => (
            <SwiperSlide key={notary.id}>
              <NotaryCard
                imgUrl={notary.imgUrl}
                name={notary.name}
                sessions={notary.sessions}
                description={notary.description}
                city={notary.city}
                state={notary.state}
              />
            </SwiperSlide>
          ))}
          <SwiperSlide />
        </Swiper>
      </div>
    </div>
  );
};
