import { FC } from 'react';

import {
  Button,
  Input,
} from '@material-tailwind/react';
import { useWaitingList } from '../../hooks/useWaitingList';

export const WaitingList: FC = () => {
  const {
    email,
    error,
    isSubmited,
    onChangeHandler,
    onSubmitHandler,
  } = useWaitingList();

  const renderInpuGroup = () => (
    <>
      <Input
        label='Enter your email'
        error={!!error}
        value={email}
        onChange={onChangeHandler}

      />

      {!!error && (
        <p className="text-primaryRed text-xs ml-2 mt-2">
          {error}
        </p>
      )}

      <Button
        type='button'
        className='w-full mt-8 normal-case rounded font-medium'
        disabled={!!error || !email}
        onClick={onSubmitHandler}
      >
        Add Me to the Waiting List
      </Button>
    </>
  );

  return (
    <div className='mb-8'>
      <div className="px-4 py-2 mt-2 rounded bg-primaryRed/10 mb-6"> 
        <p className="text-primaryRed text-xs">
          Join our waiting list and as soon as this state becomes available to RONs in Notary Hub, we will notify you. Notary Hub is not yet available for RONs in all states.
        </p>
      </div>

      {!isSubmited && renderInpuGroup()}

      {isSubmited && (
        <p className='text-secondaryBlue text-sm mt-6'>
          Thank you for your interest. Our Support team will contact you shortly.
        </p>
      )}
    </div>
  );
};
