import { FC } from 'react';

import { INotaryCardProps } from './notaryCard.interface';

export const NotaryCard: FC<INotaryCardProps> = (props) => {
  const {
    imgUrl,
    name,
    sessions,
    description,
    city,
    state
  } = props;

  return (
    <article className='flex flex-col items-center bg-white w-[16.875rem] h-[26.875rem] rounded border border-solid border-lightGray mt-20'>
      <img
        src={imgUrl}
        alt="notary"
        className='mt-[-2rem] mb-[2rem] z-[1] rounded'
      />

      <h4 className='font-bold mb-1'>
        {name}
      </h4>

      <p className='text-primaryBlue mb-4'>
        {sessions}+ Sessions
      </p>

      <p className='text-center text-gray text-sm mb-4 overflow-hidden'>
        {description}
      </p>

      <p className='text-gray font-bold'>
        {`${city}, ${state}`}
      </p>
    </article>
  );
};
