import { FC } from 'react';

import { CustomerSlider } from '../../../components/customerSlider/CustomerSlider';
import { consumerFeedbackData } from '../../../data/consumerFeedbackData';

export const ConsumerFeedback: FC = () => {
  return (
    <CustomerSlider slides={consumerFeedbackData} />
  );
};
