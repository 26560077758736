export const faqDataConsumer = [
  {
    id: 1,
    header: 'What is a Notary HUB?',
    text: 'Notary Hub is a safe, legal, and secure platform for remote online notarization. '
  },
  {
    id: 2,
    header: 'How does signing work?',
    text: `Once you have completed identity verification and knowledge-based authentication and you and the notary have agreed on a signing schedule, log in at the designated time and enable your audio and video.
    
    The notary will walk you through the signing process. During this process, you will digitally sign your documents in the areas indicated by the notary. After signing, you can download your documents immediately.`,
  },
  {
    id: 3,
    header: 'How long does the process take?',
    text: `Creating your account and requesting a notarization can be completed in just a few minutes.
    
    The duration of your session with your notary depends on the number of documents to be signed, but typically one notarization can be completed in five minute or can last up to 30 minutes (depending on the number of notarizations and documents).`
  },
  {
    id: 4,
    header: 'What is a remote online notary?',
    text: `A remote notary is a legally commissioned notary public who is authorized to conduct notarizations over the internet via digital tools and a live audio-video call.
    
    Legally, all notaries, no matter how they perform their service, must observe as a customer signs a document. Historically, this has required that the notary and signer must travel to meet one another in-person where the notary serves as a witness during the signing event. Remote notarization allows this process to occur online, anytime, without requiring a physical meeting.
    
    RONs use a digital signature and digital notary seal to notarize digital documents and validate with a digital certificate.`
  },
  {
    id: 5,
    header: 'Is the process safe and legal?',
    text: `When you complete a notarization process with a remote online notary, the finalized PDF document is digitally signed. The digital fingerprint is embedded in the PDF which allows others to verify that it has not been altered after the signing took place. The signature guarantees that the document hasn’t been tampered with, and it also guarantees the identity of the Notary signer. The files you upload to the site will be stored securely and only be available to you and your Notary. The information you provide to verify your identity is only used to pass data securely to our identity partner.`,
  },
  {
    id: 6,
    header: 'How much does it cost?',
    text: 
    <>
      As a consumer,
      {' '}
        <a
          href="https://app.notaryhub.com/notarize-a-document-online-find-a-notary"
          className='underline'
        >
          signing up for Notary Hub is FREE.
        </a>
      {' '}
      {'\n'}
      {'\n'}
      Once you are ready to engage in a digital signing experience you will be charged a flat fee per document that is signed and notarized.
      {'\n'}
      {'\n'}
      A single notarization ("seal") is $21; additional seals are $15 each.
    </>
  }
];
