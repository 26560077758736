import { FC } from 'react';

import { IVideoBlockProps } from './videoBlock.interface';
import { LogoTitle } from '../logoTitle/LogoTitle';
import { YouTube } from '../youTube/YouTube';

export const VideoBlock: FC<IVideoBlockProps> = (props) => {
  const {
    text,
    title,
    videoUrl,
    reverse,
    className,
  } = props;

  const rowDirection = reverse ? 'flex-row-reverse' : 'flex-row' 

  return (
    <section className={
      `${className} relative container mx-auto lg:flex lg:${rowDirection} lg:justify-between lg:items-center lg:gap-[8.125rem]`
    }>
      <div className='w-full max-w-[29.375rem]'>
        <LogoTitle title={title} />

        <p className="font-medium text-gray text-sm mb-8 md:mb-12 leading-6 whitespace-pre-line">
          {text}
        </p>
      </div>

      <div className="relative w-full max-w-[61rem]">
        <div className='absolute bg-lightGray w-full h-full rounded-[1rem] top-[-0.75rem] left-[-0.75rem]' />

        <YouTube
          videoUrl={videoUrl}
        />
      </div>
    </section>
  );
};
