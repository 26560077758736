import { FC } from 'react';

import notaryStart from '../../../../assets/img/notaryStart.png';
import { StartScreen } from '../../../../components/startScreen/StartScreen';
import { StartContent } from './SratContent';

export const NotaryStart: FC = () => {
  return (
    <StartScreen
      content={<StartContent />}
      image={
        <div className='flex justify-center items-center max-w-[35rem]'>
          <img
            src={notaryStart}
            alt="Notary Shtamp"
            className='mt-[-1.25rem] ml-[-0.5rem]'
          />
        </div>
      }
    />
  );
};
