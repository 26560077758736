import { FC } from 'react';

import { IChaineCardProps } from './chainCard.interface';

export const ChaineCard: FC<IChaineCardProps> = (props) => {
  const {
    title,
    text,
    className,
  } = props;

  return (
    <article className={`flex flex-col items-center justify-center ${className}`}>
      <h4 className='text-semibold mb-4'>
        {title}
      </h4>

      <p className='text-center text-gray text-sm w-[14.5rem]'>
        {text}
      </p>
    </article>
  );
};
