import { FC } from 'react';

import consumerStart from '../../../../assets/img/consumerStart.png';
import { StartScreen } from '../../../../components/startScreen/StartScreen';
import { StartContent } from './StartContent';

export const ConsumerStart: FC = () => {
  return (
    <StartScreen
      content={<StartContent />}
      image={
        <>
          <div className='flex justify-center items-center max-w-[40rem]'>
            <img
              src={consumerStart}
              alt="Device"
              className='ml-[-1.25rem]'
            />
          </div>
        </>
      }
    />
  );
};
