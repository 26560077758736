import { FC } from "react";
import {
  Select,
  Option,
  Button,
} from "@material-tailwind/react";
import { useNavigate } from "react-router";

import { LogoTitle } from "../../../../../components/logoTitle/LogoTitle";
import allStates from '../../../../../data/allStates.json';
import { WaitingList } from "../../../../../components/waitingList/WaitingList";
import { useRegistrationContext } from "../../../../../hooks/useRegistarationContext";

export const StateSelection: FC = () => {
  const {
    isSuportingState,
    selectedState,
    selectedStateName,
    setStep,
    setSelectedState,
  } = useRegistrationContext();
  const naviagte = useNavigate();

  const onSelectStateHandler = (state: string | undefined) => {
    if (!state) return;
    
    setSelectedState(state);
  };

  const seePlanHandler = () => {
    setStep(2);
    naviagte('/registration');
  }

  return (
    <article className="max-w-[24.4375rem] w-full">
      <LogoTitle
        theme='dark'
        title='You As A Notary'
        classNames="mb-[2rem]"
      />

      <p className="text-white text-sm mb-4">
        In which state will you deliver RON services?
      </p>

      <Select
        label="Select State"
        onChange={onSelectStateHandler}
        value={selectedState}
        className="mb-8"
      >
        {allStates.map((state) => (
          <Option
            key={state.id} 
            value={state.abbreviation}
          >
            {`${state.abbreviation}, ${state.name}`}
          </Option>
        ))}
      </Select>

      {!isSuportingState && <WaitingList />}

      {isSuportingState && (
        <Button
          type="button"
          onClick={seePlanHandler}
          className="my-8 w-full normal-case rounded font-medium"     
        >
          {`See All Plans For ${selectedStateName}`}
        </Button>
      )}

      <p className="text-secondaryBlue text-sm mb-8">
        Not a registered notary?
      </p>

      <Button
        type="button"
        onClick={() => naviagte('/registration')}
        className="w-full normal-case rounded font-medium"
      >
        Register
      </Button>

    </article>
  );
};
