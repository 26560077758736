import { FC } from "react";

import { ReactComponent as NotaryChainIcon } from '../../../assets/icon/notaryChain.svg'
import { LogoTitle } from "../../../components/logoTitle/LogoTitle";
import { ChaineCard } from "../../../components/chainCard/ChainCard";

export const NotaryChain: FC = () => {
  return (
    <section className="max-w-full mx-auto pt-[6rem] pb-[4rem] overflow-hidden">
      <div className="container mx-auto mb-[2rem]">
        <LogoTitle title="How it Work?" />
      </div>

      <div className='max-w-[1440px] overflow-x-auto ml-[-6.25rem] md:mx-auto'>
        <NotaryChainIcon className="w-[1440px]"/>

        <div className="flex px-[100px] min-w-[1440px] pb-10">
          <ChaineCard
            className="w-1/5 mt-[-200px]"
            title="Register"
            text="Register your Notary Hub account"
          />

          <ChaineCard
            className="w-1/5"
            title="Get RON Endorsement"
            text="Get RON endorsement for your state"
          />

          <ChaineCard
            className="w-1/5 mt-[-200px] ml-[-10px]"
            title="Subscription"
            text="Choose a Notary Hub subscription that fits your needs"
          />

          <ChaineCard
            className="w-1/5"
            title="Certificate"
            text="Add a digital document signing certificate to your Notary Hub account"
          />

          <ChaineCard
            className="w-1/5 mt-[-100px]"
            title="Onboarding"
            text="Complete other onboarding steps"
          />
        </div>
      </div>
    </section>
  );
};
