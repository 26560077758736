import { FC } from "react";

import { HomeStart } from "./components/homeStart/HomeStart";
import { HomeYouTube } from "./components/HomeYouTube";
import { Benefits } from "./components/Benefits";
import { Trusted } from "./components/trusted/Trusted";
import { HomeChain } from "./components/HomeChain";
import { HowItWork } from "./components/HowItWork";
import { HomeFeedback } from "./components/HomeFeedback";
import { HomeFAQ } from "./components/HomeFAQ";
import useDocumentTitle from "../../hooks/useDocumentTitle";

interface HomePageProps {
  title?: string;
}
export const HomePage: FC<HomePageProps> = ({ title = "Home" }) => {
  useDocumentTitle(title);

  return (
    <main>
      <HomeStart />

      <HomeYouTube />

      <Benefits />

      <Trusted />

      <HomeChain />

      <HowItWork />

      <HomeFeedback />

      <HomeFAQ />
    </main>
  );
};
