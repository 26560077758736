import { FC } from 'react';

import { StepButton } from '../stepButton/StepButton';
import { useRegistrationContext } from '../../hooks/useRegistarationContext';

export const FormStepper: FC = () => {
  const {
    step,
    stepBackHandler,
  } = useRegistrationContext();

  return (
    <div className='w-full flex items-center gap-1 py-2'>
      <StepButton
        value={1}
        currentStep={step}
        stepBackHandler={stepBackHandler}
      />

      <StepButton
        value={2}
        currentStep={step}
        stepBackHandler={stepBackHandler}
      />

      <StepButton
        value={3}
        currentStep={step}
        stepBackHandler={stepBackHandler}
      />

      <StepButton
        value={4}
        currentStep={step}
        stepBackHandler={stepBackHandler}
      />
    </div>
  );
};
