import { FC } from 'react';

import { ReactComponent as PointBlueIcon } from '../../../../assets/icon/pointBlue.svg';
import { ReactComponent as PointGreenIcon } from '../../../../assets/icon/pointGreen.svg';
import { subscriptionsIncludesData } from '../../../../data/subscriptionsIncludesData';
import { corpRonPlan } from '../../../../data/corpRonPlan';

export const DescriptionCorp: FC = () => {
  return (
    <section>
      <article className="mb-6 lg: mt-10">
        <h2 className="text-white text-[2rem] font-semibold mb-2 text-center lg:text-left">
          Corp RON plan
        </h2>

        <ul>
          {corpRonPlan.map((item) => (
            <li
              key={item.id}
              className="flex items-center gap-4 py-2 text-white text-xs lg:text-base"
            >
              <span>
                <PointBlueIcon className='w-[12px] h-[12px]' /> 
              </span>

              {item.text}
            </li>
          ))}
        </ul>
      </article>

      <article>
        <h2 className="text-agry text-2xl font-semibold mb-2 text-center lg:text-left">
          Corp RON Notary Hub subscriptions include
        </h2>

        <ul>
          {subscriptionsIncludesData.map((item) => (
            <li
              key={item.id}
              className="flex items-center gap-4 py-2 text-white text-xs lg:text-base"
            >
              <span>
                <PointGreenIcon className='w-[12px] h-[12px]' />
              </span>

              {item.text}
            </li>
          ))}
        </ul>
      </article>
    </section>
  );
};
