export const getStateHighlight = (state: string, selectedState: string, isSuportingState: boolean, isDarkTheme: boolean) => {
  if (!isSuportingState && state === selectedState) {
    return '#F85255';
  };

  if (state === selectedState) {
    return '#009EF7';
  };

  return isDarkTheme ? '#21466C' : '#B9CFE7';
};
