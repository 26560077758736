import {
  FC,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollTop: FC = () => {
  const lacation = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    })
  }, [lacation.pathname])

  return null;
};
