import { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { IComingOutCardProps } from './comingOutCard.interface';

export const ComingOutCard: FC<IComingOutCardProps> = (props) => {
  const {
    iconUrl,
    title,
    text,
    redirectTo,
    buttonColor,
  } = props;

  return (
    <article className='w-[21.5625] h-[23.125] lg:w-[35.625rem] lg:h-[18.75rem] rounded
    flex flex-col lg:flex-row-reverse items-center justify-center
    bg-white text-xl px-4 py-8 lg:px-[3.125rem] lg:py-[3.125rem] shadow-around'>
      <img
        src={iconUrl}
        alt={title}
        className='mb-6 lg:w-[18.75rem]'
      />

      <div className='flex flex-col items-center lg:flex lg:flex-col lg:items-start lg:max-w-[18.75rem]'>
        <h4 className='font-semibold mb-2 lg:text-3xl'>
          {title}
        </h4>

        <p className='text-center text-gray text-sm overflow-hidden mb-6 lg:text-left leading-6'>
          {text}
        </p>

        <NavLink 
          to={redirectTo}
          className={`w-full py-2 text-center text-sm text-white bg-${buttonColor} rounded lg:max-w-[10.625rem] hover:opacity-90 duration-300`}
        >
          {title}
        </NavLink>
      </div>
    </article>
  );
};
