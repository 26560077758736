import {
  FC,
  useRef,
} from 'react';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';
import { Navigation } from 'swiper';
import { IconButton } from '@material-tailwind/react';
import 'swiper/css';
import 'swiper/css/navigation';

import { ReactComponent as ChevronRightIcon } from "../../assets/icon/chevronRight.svg";
import { ReactComponent as ChevronLeftIcon } from "../../assets/icon/chevronLeft.svg";
import { LogoTitle } from '../logoTitle/LogoTitle';
import { CustomerCard } from '../customerCard/CustomerCard';
import { breakpoints } from './customerSlider.const';
import { ICustomerSliderProps } from './customerSlider.interface';

export const CustomerSlider: FC<ICustomerSliderProps> = ({slides}) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const controlButoonClasses = 'w-[2rem] h-[2rem] md:w-[3rem] md:h-[3rem] flex justify-center items-center bg-lightBlue';

  return (
    <section className='max-w-full mx-auto overflow-hidden'>
      <div className='max-w-[640px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] 2xl:max-w-[1536px] mx-auto  md:pt-[5rem] pb-[1rem] px-4 overflow-visible'>
        <div className='flex items-center justify-between mb-[3rem] pr-5'>
          <LogoTitle title='What people are saying'/>

          <div className='flex justify-center items-center gap-4 mt-5 md:mt-12'>
            <IconButton
              ref={navigationPrevRef}
              type="button"
              className={controlButoonClasses}
            >
              <ChevronLeftIcon className="text-gray" />
            </IconButton>

            <IconButton
              ref={navigationNextRef}
              type="button"      
              className={controlButoonClasses}
            >
              <ChevronRightIcon className="text-gray" />
            </IconButton>
          </div>
        </div>

        <div className='w-[640px] md:w-[960px] lg:w-[1600px] 2xl:w-[1800px] mx-auto overflow-visible'>
          <Swiper
            modules={[Navigation]}
            spaceBetween={0}
            breakpoints={breakpoints}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
              disabledClass: 'scale-90 bg-lightGray',
            }}
            onBeforeInit={swiper => {
              if (typeof swiper.params.navigation !== "boolean") {
                swiper.params.navigation!.nextEl = navigationNextRef.current;
                swiper.params.navigation!.prevEl = navigationPrevRef.current;
              }
            }}
          >
            {slides.map((customer) => (
              <SwiperSlide key={customer.id}>
                <CustomerCard
                  imgUrl={customer.imgUrl}
                  name={customer.name}
                  text={customer.text}
                  position={customer.position}
                />
              </SwiperSlide>
            ))}
              <SwiperSlide/>
          </Swiper>
        </div>
      </div>
    </section>
  );
};
