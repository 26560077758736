import { FC } from "react";

import { YouTube } from "../../../components/youTube/YouTube";
import { ReactComponent as MarkIcon } from "../../../assets/icon/markWhite.svg";

export const HomeYouTube: FC = () => {
  return (
    <section className="relative container mx-auto flex justify-center pt-[3rem] pb-[3rem] overflow-hidden lg:pt-[8rem]">
      <div className="w-full max-w-[61rem]">
        <YouTube
          videoUrl='https://www.youtube.com/watch?v=CEQZivJOtM0'
        />
      </div>
      
      <MarkIcon className="absolute w-[12.5rem] right-[-1.5rem] bottom-[-13rem] z-[-1] lg:right-[12rem]" />
    </section>
  );
};
