import { FC } from "react";

import { IStepButtonProps } from "./stepButton.interface";

export const StepButton: FC<IStepButtonProps> = (props) => {
  const {
    value,
    currentStep,
    stepBackHandler,
  } = props;

  const isActive = currentStep === value;
  
  return (
    <button
      type='button'
      onClick={() => stepBackHandler(value)}
      disabled={value > currentStep}
      className={`w-full text-left text-xs ${isActive ? 'text-agry' : 'text-lightGray'}`}
    >
      {`Step ${value}`}
      
      <span className={`block w-full h-1 mt-1 rounded ${isActive ? 'bg-agry' : 'bg-lightGray'}`} />
    </button>
  );
};
