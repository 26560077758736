import { FC } from "react";
import { Button } from "@material-tailwind/react";

import { PlanCard } from "../../../../components/planCard/PlanCard";
import planConsumerIcon from '../../../../assets/icon/planConsumer.svg';
import planOrganizationIcon from '../../../../assets/icon/planOrganization.svg';
import { YouTube } from "../../../../components/youTube/YouTube";
import { useRegistrationContext } from "../../../../hooks/useRegistarationContext";
import { FormTemplate } from "../../../../components/formTemplate/FormTemplate";

export const FormPlan: FC = () => {
  const {
    plan,
    setPlan,
    setStep,
    selectedStateName,
  } = useRegistrationContext();

  const onClickHandler = () => {
    setStep(3)
  };

  return (
    <div className="flex flex-col justify-center items-center lg:flex-row lg:justify-between gap-[6.25rem]">
      <FormTemplate
        subTitle="SELECT PLAN"
        title="Who will pay for the online notary services you will provide?"
      >
        <PlanCard
          onClick={() => setPlan('self')}
          isActive={plan === 'self'}
          iconUrl={planOrganizationIcon}
          title="My Clients"
          text="I’m an independent notary that gets paid for signings"
          className="mb-4"
        />

        <PlanCard
          onClick={() => setPlan('corp')}
          isActive={plan === 'corp'}
          iconUrl={planConsumerIcon}
          title="My Company"
          text="I perform notary services on behalf of my company"
          className="mb-8"
        />

        <Button
          type="button"
          onClick={onClickHandler}
          className="mt-6 w-full normal-case rounded font-medium"
        >
          {`See ${plan.charAt(0).toLocaleUpperCase() + plan.slice(1)} RON Plans For ${selectedStateName}`}
        </Button>
      </FormTemplate>

      <div className="relative w-full max-w-[61rem]">
        <div className='absolute bg-[#0F3257] w-full h-full rounded-lg top-[-0.75rem] left-[-0.75rem]' />

        <div className='absolute bg-[#0F3257] w-full h-full rounded-lg bottom-[-0.75rem] right-[-0.75rem]' />

        <YouTube
          videoUrl={'https://www.youtube.com/watch?v=0L5hj4mcjeA'}
        />

      </div>
    </div>
  );
};
