import { FC } from 'react';

import { ReactComponent as PointBlueIcon } from "../../assets/icon/pointBlue.svg";

const selfIncludes = [
  {
    id: 1,
    text: "Get paid instantly for signings",
  },
  {
    id: 2,
    text: "Unlimited signing sessions",
  },
  {
    id: 3,
    text: "Securely stored Digital Signature",
  },
  {
    id: 4,
    text: "Securely stored Digital Notary Stamp",
  },
  {
    id: 5,
    text: "24x7 access to the notarization platform",
  },
  {
    id: 6,
    text: "Marketing to promote yourself for mobile or RON Notarial opportunities",
  },
  {
    id: 7,
    text: "Audio/visual live sessions",
  },
  {
    id: 8,
    text: "ID Verification / KBA for your constituents",
  },
  {
    id: 9,
    text: "Scheduling",
  },
];

export const SelfPlanDescription: FC = () => {
  return (
    <>
      <h2>INCLUDED</h2>

      <ul className="mb-5">
        {selfIncludes.map((item) => (
          <li
            key={item.id}
            className="flex items-center gap-4 py-1 text-white text-xs lg:text-base"
          >
            <span>
              <PointBlueIcon className="w-[12px] h-[12px]" />
            </span>

            {item.text}
          </li>
        ))}
      </ul>

      <p className="mb-5 pl-[1rem]">
        For each signing session conducted the constituent pays for each stamp applied to the document. As a Self RON, you get to keep 75% of the fees.
      </p>

      <ul className="mb-5 pl-[2rem]">
        <li className="flex items-center gap-4 py-1 text-white text-xs lg:text-base">
          <span>
            <PointBlueIcon className="w-[12px] h-[12px]" />
          </span>

          First stamp: $21
        </li>

        <li className="flex items-center gap-4 py-1 text-white text-xs lg:text-base">
          <span>
            <PointBlueIcon className="w-[12px] h-[12px]" />
          </span>

          Each additional stamp: $15
        </li>
      </ul>

      <h2 className="pl-[1rem]">
        NOT INCLUDED
      </h2>

      <ul>
        <li className="flex items-center gap-4 py-1 text-white text-xs lg:text-base pl-[1rem]">
          <span>
            <PointBlueIcon className="w-[12px] h-[12px]" />
          </span>

          Required Digital Sertificate
        </li>
      </ul>

      <p className="text-xs text-primaryRed pl-[1rem]">
        Notary Hub will facilitate your discounted purchase of our cloud-based SSL digital certificate after your subscription is chosen.
      </p>
    </>
  );
};
