import { FC } from 'react';

import { IRegisterNavProps } from './registerNav.interface';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icon/arrowRight.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icon/arrowLeft.svg';

export const RegisterNav: FC<IRegisterNavProps> = (props) => {
  const {
    setNavPageHandler,
  } = props;

  const linkClasses = 'flex justify-between items-center w-full text-white';

  return (
    <nav className="container mx-auto flex flex-col items-start gap-10">
      <button
        type='button'
        onClick={() => setNavPageHandler('menu')}
        className='flex gap-5 items-center w-full text-white'
      >
        <ArrowLeftIcon />
        Back
      </button>

      <a
        href="https://app.notaryhub.com/notarize-a-document-online-find-a-notary"
        className={linkClasses}
      >
        Consumer - Schedule a Notary
        <ArrowRightIcon />
      </a>

      <a
        href="https://app.notaryhub.com/online-notary-options-become-ron"
        className={linkClasses}
      >
        Notary - Register as a RON
        <ArrowRightIcon />
      </a>
    </nav>
  );
};
