import { FC } from 'react';
import {
  Route,
  Routes,
} from 'react-router';

import { ScrollTop } from './components/scrollTop/ScrollTop';
import { Header } from './components/header/Header';
import { HomePage } from './pages/home/HomePage';
import { NotaryPage } from './pages/notary/NotaryPage';
import { ConsumerPage } from './pages/consumer/ConsumerPage';
import { RegistrationPage } from './pages/registration/RegistrationPage';
import { Footer } from './components/footer/Footer';


export const App: FC = () => {
  return (
    <>
      <ScrollTop />

      <Header />

      <Routes>
        <Route path='/' element={
          <HomePage 
            title="Notary Hub - RON Remote Online Notary Platform"
          />
          } 
        />

        <Route path='/notary' element={
          <NotaryPage 
            title="Notary Hub - Notary Platform for performing Remote Online Notary (RON)" 
          />
          } 
        />

        <Route path='/consumer' element={
          <ConsumerPage 
            title="Notary Hub - Find a Remote Notary to sign your documents online"
          />
        } 
        />

        <Route path='/registration' element={
          <RegistrationPage 
            title="Register as a RON to become an online notary" 
          />}

        />
      </Routes>

      <Footer />
    </>
  );
};
