import { FC } from 'react';

import { IStartInfoProps } from './startInfo.interface';

export const StartInfo: FC<IStartInfoProps> = (props) => {
  const {
    title,
    subtitle,
    text,
  } = props;

  return (
    <>
      <h2 className="font-semibold text-secondary text-center tracking-[0.6875rem] mb-5 lg:text-left lg:text-2xl lg:w-max">
        {subtitle}
      </h2>

      <h1 className="font-semibold lg:leading-[4.75rem] text-[2rem] text-center mb-4 lg:text-[3.75rem] lg:text-left whitespace-pre-line">
        {title}
      </h1>

      <p className="font-medium text-gray text-center text-sm mb-8 md:mb-12 lg:text-left lg:max-w-[30rem]">
        {text}
      </p>
    </>
  );
};
