import { FC } from "react";
import { NavLink } from "react-router-dom";

import { StartInfo } from "../../../../components/startInfo/StartInfo";

export const StartContent: FC = () => {
  return (
    <>
      <StartInfo
        subtitle="NEED A NOTARY ASAP?"
        title={`Online Notary \n Services`}
        text="Connect with a remote online notary anytime, anywhere, without the need for a physical meeting."
      />

      <div className="flex w-full justify-center lg:justify-start mb-12">
        <a
          href="https://app.notaryhub.com/notarize-a-document-online-find-a-notary?register-from=consumers"
          className="py-3 px-7 bg-secondary rounded text-white text-center hover:opacity-90"
        >
          Schedule A Notary
        </a>
      </div>

      <div className="flex flex-col">
        <p className="font-medium text-gray text-sm mb-2">
          Notaries: learn more about
        </p>

        <NavLink
          to="/notary"
          className="font-medium text-sm underline"
        >
           Notary Hub’s Remote Online Notary (RON) platform 
        </NavLink>
      </div>
    </>
  );
};
