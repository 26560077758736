import { FC } from 'react';

import { LogoTitle } from '../../../components/logoTitle/LogoTitle';
import { BenefitsCard } from '../../../components/benefitsCard/BenefitsCard';
import { benefitsCardMock } from '../../../data/benefitsCardData';

export const Benefits: FC = () => {
  return (
    <section className='container mx-auto py-[4.375rem]'>
      <LogoTitle
        title='Benefits'
      />
      
      <div className='flex flex-col items-center justify-center gap-2 mt-10 md:flex-row md:flex-wrap md:gap-6'>
        {benefitsCardMock.map((card) => (
          <BenefitsCard
            key={card.id}
            icon={card.icon}
            title={card.title}
            text={card.text}
          />
        ))}
      </div>
    </section>
  );
};
