import iAmNotaryIcon from '../assets/icon/iAmNotary.svg';
import iAmConsumerIcon from '../assets/icon/iAmConsumer.svg';

export const iAmConsumerMock = {
  id: 1,
  icon: iAmConsumerIcon,
  title: 'I need a notary',
  text: 'Sign your documents quickly and remotely. Create an account and schedule an appointment to have your documents notarized electronically.',
  redirectTo: '/consumer',
};

export const iAmNotaryMock = {
  id: 2,
  icon: iAmNotaryIcon,
  title: 'I am a notary',
  text: 'Learn how to become an online notary, and find a subscription that fits your notary needs, whether you’re an independent notary or you’re a notary who works on a company’s behalf. ',
  redirectTo: '/notary',
};
