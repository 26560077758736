export const corpRonPlan = [
  {
    id: 1,
    text: 'Constituents are not prompted to pay with each signing',
  },
  {
    id: 2,
    text: 
      <span>
        Inexpensive start-up costs
        {' '}
        <span className='text-gray'>
          (as low as $5/month with 4-year plan)
        </span>
      </span>,
  },
  {
    id: 3,
    text: 'Your company could choose to charge your clients up to a maximum of $25.00 per remote online notarization.',
  },
  {
    id: 4,
    text: 'Ron-arize when you want and where you want (from the comfort of your home or office)!',
  },
];
